import { createSlice } from '@reduxjs/toolkit'

let initialState = {
    userLogin: true,
    preferencesUpdated: 0,
}

const appSlice = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        userLogin(state) {
            state.userLogin = true;
        },
        userLogout(state) {
            state.userLogin = false;
        },
        preferencesUpdated(state) {
            state.preferencesUpdated++;
        }
    }
})


export const appActions = appSlice.actions;

export default appSlice.reducer;