import { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PlanningComp from "./main/planning";
import PlanningActionResultComp from "./main/planning-action-result";
import PlanningPortal from './main/health-planning/index.js'
import Home from './main/home/index.js'
import Login from './main/login/index.js'
import PageNotFound from "./main/page-not-found";
import { useSelector } from 'react-redux';
const AppRoutes = (props) => {
    const isUser = useSelector(state => state.application.userLogin)
    const isAdmin = useSelector(state => state.application.adminLogin)
    const staticDataLoaded = props.staticDataLoaded;

    return (
        <Switch>
            <Route path={"/"} exact render={() => <Home />} />
            {/* <Route path={"/admin"} exact render={() => isAdmin ? <Admin /> : <Redirect to={{ pathname: '/' }} />} /> */}
            <Route path={"/login"} exact render={() => !isUser ? <Login /> : <Redirect to={{ pathname: '/' }} />} />
            <Route path={"/health-planning-portal"} exact render={() => isUser ? <PlanningPortal staticDataLoaded={staticDataLoaded} /> : <Redirect to={{ pathname: '/login' }} />} />
            <Route path={"/urban-planning-portal"} exact render={() => isUser ? <PlanningComp staticDataLoaded={staticDataLoaded} /> : <Redirect to={{ pathname: '/login' }} />} />


            {isUser && <Route path={"/urban-planning-portal/result"} exact component={PlanningActionResultComp} />}
            <Route path="*" component={PageNotFound} />
        </Switch>

    )
};
export default AppRoutes;