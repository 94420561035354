import styles from './Login.module.css'
import { useHistory } from 'react-router-dom';
import { ArrowSmRightIcon } from '@heroicons/react/outline'
import { Button, Checkbox, Form, Input, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { appActions } from '../../../../redux/reducers/appReducer'
import API from "../../../../services/API"
import { ENDPOINTS } from "../../../../services/endpoints";

const openNotification = (type, title, msg) => {
    notification[type]({
        message: title,
        description:
            msg,
    });
};

export default function Login() {
    const dispatch = useDispatch();
    const history = useHistory();
    const onRequestLogin = (values) => {
        const payload = {
            "username": values.username,
            "password": values.password
        }
        API.post(ENDPOINTS.API_USER_LOGIN, payload)
            .then(
                res => {
                    if (res.status === 200) {
                        if (values.remember) {
                            localStorage.setItem('token', res.data.jwtToken)
                        }
                        dispatch(appActions.userLogin());
                    } else {
                        openNotification('error', 'Something went wrong', 'Incorrect username or password.')
                    }
                }
            )
            .catch(err =>
                openNotification('error', 'Something went wrong', 'Incorrect username or password.')
            )
    };
    return (
        <div className={styles.login}>
            <div className={styles.form}>
                <div className={styles.form_title}>Login to your project</div>
                <div className={styles.form_wrapper}>
                    <Form
                        preserve={false}
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{ remember: true }}
                        onFinish={onRequestLogin}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Project ID"
                            name="username"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                    <div onClick={() => history.push('/')}><span className={styles.link}>{`Back to homepage`} <ArrowSmRightIcon className={styles.link_icon} /></span></div>
                </div>
            </div>
        </div >
    )
}