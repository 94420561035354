import {
	PLANNING_ACTIONS_FETCHED, 
	PLANNING_ACTION_UPDATED, 
	PLANNING_ACTION_ADDED, 
	PLANNING_ACTION_DELETED,
	CHART_DATA_FETCHED,
	CHART_DATA_ON_SCORE_UPDATE_FETCHED
} from "../actions/types";

const initialState = {
	cities : {},
	averagesPerItemByCity: {},
 	chartData: {},
	chartDataOnScoreUpdate: {}
}

export default (state = initialState, action) => {
	let city = "";
	let updatedCities = {};
	let updatedPlanningActionsArr = [];
	let updatedPlanningAction = {};

	let planningActionId = "";
	let key = "";	
	let updatedChartData = {};

	switch(action.type) {

		case PLANNING_ACTIONS_FETCHED:
			city = action.city;

			updatedCities = {
				...state.cities,
				[city]: action.data
			};

			/*const updatedAveragesPerItemByCity = {
				...state.averagesPerItemByCity,
				[city]: action.data.averagesPerItem
			};*/
			//				averagesPerItemByCity: updatedAveragesPerItemByCity

			return {
				...state,
				cities: updatedCities
			}

		case PLANNING_ACTION_UPDATED:
			city = action.city;

			const planningActionIndex = state.cities[city].findIndex((planningAction => planningAction.planningActionId === action.data.actionId));
            if(planningActionIndex != -1) {
				updatedPlanningAction = {
					"planningActionId": action.data.actionId,
					"name": action.data.actionName,					
					"description": action.data.actionDescription,					
					"items": action.data.scoreItems,
				}
				updatedPlanningActionsArr = [
					...state.cities[city].slice(0, planningActionIndex),
					updatedPlanningAction,
					...state.cities[city].slice(planningActionIndex+1, state.cities[city].length)
				]
				updatedCities = {
					...state.cities,
					[city]: updatedPlanningActionsArr
				}
				return {
					...state,
					cities: updatedCities
				}
			}
		
		case CHART_DATA_FETCHED:
				city = action.city;
				planningActionId = action.planningActionId;
				key = `${city}_${planningActionId}`;
				
				updatedChartData = {
					...state.chartData,
					[key]: action.data
				};
		
				return {
					...state,
					chartData: updatedChartData,
					chartDataOnScoreUpdate: updatedChartData
				}

		case CHART_DATA_ON_SCORE_UPDATE_FETCHED:
			city = action.city;
			planningActionId = action.planningActionId;
			key = `${city}_${planningActionId}`;
			
			updatedChartData = {
				...state.chartDataOnScoreUpdate,
				[key]: action.data
			};
	
			return {
				...state,
				chartDataOnScoreUpdate: updatedChartData
			}

		default:
			return state;
	}
}

