import { Collapse, Button } from 'antd';
import React from 'react';
import styles from './InputComponent.module.css'
import CustomCheckbox from '../customCheckbox/CustomCheckbox.js';
import { CaretRightOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { SaveOutlined } from '@ant-design/icons';
import UserManual from '../userManual/UserManual.js';

const { Panel } = Collapse;

const InputComponent = ({ data, checkboxes, setCheckboxes, selectAll, clearAll, updateCheckboxes }) => {
    const history = useHistory();
    const [visible, setVisible] = useState(true);
    const [activeKey, setActiveKey] = useState(null)
    const [openUserManul, setUserManual] = useState(false)
    const closeManual = () => {
        setUserManual(false)
    }
    const openManual = () => {
        setUserManual(true)
    }
    return (
        <div>
            <div className={styles.action_items}>
                <div className={styles.item} onClick={openManual} >Guidance</div>
                <Link className={styles.item} to="/pdfs/Health_Impacts_of_Urban_Determinants.pdf"  target="_blank" download>Health indicators: find out more</Link>
            </div>
            <div className={styles.inputComponent} >

                <div className={styles.inputComponent_title}> Select the health indicators on which you want to have an impact</div>
                <div style={{ height: 55, display: 'flex', gap: 10, margin: '10px 0px', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Button onClick={selectAll} type="secondary" shape="round"  > Select all</Button>
                    <Button onClick={clearAll} type="secondary" shape="round" > Clear all</Button>
                    <Button onClick={updateCheckboxes} type="secondary" shape="round" className={styles.saveIcon} icon={<SaveOutlined />} >Save changes</Button>
                </div>
                <Collapse
                    className={styles.inputComponent_collapsible}
                    onChange={(key) => setActiveKey(key)}
                    defaultActiveKey={['0']}
                >
                    {
                        data !== undefined && data?.map((collapsible_content, idx) =>
                            <Panel
                                header={collapsible_content.name} key={idx} style={{ backgroundColor: collapsible_content.color, fontWeight: 600 }}
                                showArrow={null}
                                extra={
                                    <div className={styles.extras_wrapper}>
                                        <CaretRightOutlined
                                            className={styles.extras_btn}
                                            onClick={() => visible && setActiveKey((state) => state)}
                                            style={{
                                                transform: activeKey?.includes(String(idx)) ? 'rotate(90deg' : 'rotate(0deg)',
                                                cursor: visible ? 'pointer' : 'not-allowed'
                                            }}
                                        />
                                    </div>
                                }
                            >
                                <div className={styles.collapse_content} id={styles.collapsible} >
                                    {
                                        collapsible_content.children?.map((row, index) => {

                                            return <CustomCheckbox index={index} setCheckboxes={setCheckboxes} checkboxes={checkboxes} label={row.label} key={index} id={row.id} color={collapsible_content.color} classType={collapsible_content.classType} />
                                        })
                                    }
                                </div>
                            </Panel>)
                    }

                </Collapse>
                {openUserManul && <UserManual onClose={closeManual} />}
            </div >
        </div>
    )
}

export default InputComponent;