import React from "react";
import styles from './NotFound.module.css'
import { Button } from 'antd';
import { useHistory } from "react-router-dom";

const PageNotFound = () => {
    const history = useHistory();
    const redirectHandler = () => {
        history.push('/')
    }
    return (
        <div className={styles.page}>
            <div className={styles.error_code}> Error 404 </div>
            <div className={styles.error_title}>UH OH! You are lost. </div>
            <div className={styles.error_msg}>
                The page you are looking for does not exist. How you got here is a mystery. But you can escape by clicking on the button below.
            </div>
            <Button id={styles.redirect_btn} onClick={redirectHandler}>Home</Button>
        </div>
    )
};
export default PageNotFound;