import React from 'react'
import { Button } from 'antd';
import styles from './QuickScan.module.css'
import urban_logo from '../../../../assets/images/urban-planning-logo.png';

export default function UserManual({ onClose }) {
    return (
        <div className={styles.modal} >
            <div className={styles.modal_body}>
                <div className={styles.userManual_head}>
                    <img height='100' className={styles.userManual_head_img} src={urban_logo} />
                    <div className={styles.userManual_head_text}  >
                        <h1>Planning</h1>
                        <div>
                            This approach guides you through the assessment of an<span style={{ fontWeight: 700 }}> existing urban plan</span>.
                            It will help you understand how small adjustments could make a big
                            difference to the health of local people.
                        </div>
                    </div>
                </div>
                <ul className={styles.userManual_body}>
                    <li><span>{`
     Enter the different actions proposed by the plan (e.g., transform the riverfront into a
        social and environmental corridor) by clicking on the “+” icon and evaluate the impact
        each action will have on each of the 20 Urban Determinants of Health. Refer to the
        criteria listed below each item to establish whether the impact will be negative,
        neutral, low, medium or high. You can hide any urban determinants that aren't
        relevant for your project. Don't forget to click on the 'update' button when you are
        done!
        `}</span></li>
                    <li>
                        <span >

                            {`By clicking on the 'view final results' button, you will be directed to the next page.
Here you can see the overall impact of your plan on health and a more detailed view
of the impact on specific health indicators by clicking on the bars of each health
category. Your plan is assigned a score based on whether it impacts all of the urban
determinants and the degree of this impact.`}
                        </span>
                    </li>
                </ul>
                <div className={styles.modal_submit_wrapper}>

                    <Button type="secondary" className={styles.closeButton} onClick={onClose}>Close</Button>
                </div>
            </div>
        </div >

    )
}

