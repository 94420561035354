import React from "react";
import styles from "./PlusButton.module.css";
import {PlusOutlined} from "@ant-design/icons"

const PlusButtonComp = (props) => {
    return (
        <div className={styles.plusButton} onClick={props.onClick}>
            <PlusOutlined className={`${styles.plusButtonIcon} ${props.newPageInitiated && styles.plusButtonIconActive }`}/>
        </div>

    )
}
export default PlusButtonComp;