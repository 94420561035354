
import { ENDPOINTS } from "../../services/endpoints";
import API from "../../services/API";

import { CITY_INFO_FETCHED, SCORES_FETCHED, SCORE_ITEMS_FETCHED } from "./types";

export const fetchCityInfo = (domainName) => async dispatch => {
	const ERROR_MESSAGE = "Something went wrong...";

	const url = `${ENDPOINTS.API_FETCH_CITY_INFO}/${domainName}`;
	try {
		const config = {
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
		};
		const response = await API.get(url, config);
		if (response && response.data) {
			dispatch({
				type: CITY_INFO_FETCHED,
				data: response.data
			});
		}
		else {
			throw new Error(`Fetch City Info failed!`);
		}
	}
	catch (error) {
		if (undefined !== error.response) {
			throw new Error(error.response.data.ERROR);
		}
		throw error;
	}

}

export const fetchScores = () => async dispatch => {
	const ERROR_MESSAGE = "Something went wrong...";

	const url = `${ENDPOINTS.API_FETCH_SCORES}`;
	try {
		const config = {
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
		};
		const response = await API.get(url, config);
		if (response && response.data) {
			dispatch({
				type: SCORES_FETCHED,
				data: response.data
			});
		}
		else {
			throw new Error(`Fetch Scores failed!`);
		}
	}
	catch (error) {
		if (undefined !== error.response) {
			throw new Error(error.response.data.ERROR);
		}
		throw error;
	}

}

export const fetchScoreItems = () => async dispatch => {
	const ERROR_MESSAGE = "Something went wrong...";

	const url = `${ENDPOINTS.API_FETCH_SCORE_ITEMS}`;
	try {
		const config = {
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
		};
		const response = await API.get(url, config);
		if (response && response.data) {
			dispatch({
				type: SCORE_ITEMS_FETCHED,
				data: response.data
			});
		}
		else {
			throw new Error(`Fetch Score Items failed!`);
		}
	}
	catch (error) {
		if (undefined !== error.response) {
			throw new Error(error.response.data.ERROR);
		}
		throw error;
	}

}

export const updateCityDescription = (description, city) => async dispatch => {
	const ERROR_MESSAGE = "Something went wrong...";

	const url = `${ENDPOINTS.API_FETCH_CITY_INFO}`;

	try {

		const params = {
			"description": description,
			"cityId": city
		}
		const config = {
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
		};
		const response = await API.post(url, params, config);
		console.log(response)
		if (response && response.status === 200) {
		}
		else {
			throw new Error(`City description failed for the City:${city}`);
		}
	}
	catch (error) {
		if (undefined !== error.response) {
			throw new Error(error.response.data.ERROR);
		}
		throw error;
	}
}
