
import density from '../images/urban-determinants/density.png';
import housing from '../images/urban-determinants/housing.png';
import landscape from '../images/urban-determinants/landscape.png';
import mixticity from '../images/urban-determinants/mixticity.png';
import mobility from '../images/urban-determinants/mobility.png';


const determinants_description = [
    {
        id: 9,
        img: density,
        title: 'Population and residential density',
        category: 'Compact Cities',
        definition: 'A healthy city should have medium population density (between 50-150 dw/Ha), buildings between 3 to 6 storeys high, avoid low-density development, prioritise the re-use of existing buildings, and enhance continuity between the city centre and suburbs.',
        description: [

        ],
        health_impact: [
            'Physical health: Medium population density is associated with lower rates of obesity, diabetes and cardiovascular disease in adults and older people.',
            'Mental health: A compact urban environment can contribute to improved mental health and reduced risk of depression, especially in women and older people.',
            'Environmental health: Medium density areas require less motorised travel, improving noise and air pollution levels.',
            'Behaviour: Medium-density areas are more walkable, encouraging increased physical activity compared to low-density areas.',
        ],
        how_to_measure: [
            'Inhabitants/ha',
            'Dwellings/ha'
        ],
        action: [
            'Prioritise growth within the city itself, avoidinging new suburban developments',
            'Reuse obsolete spaces within the city as priority areas for growth',
        ],

    },
    {
        id: 10,
        img: density,
        title: 'Business density',
        category: 'Compact Cities',
        definition: 'Business density refers to the ratio of economic activity per inhabitant',
        description: [
            'A healthy city should create space for economic activity in accessible and well-connected areas, foster commerce in a balanced way alongside housing, prioritise the re-use of existing buildings, promote locally-based businesses, and link new areas of economic activity with existing ones.',
        ],
        health_impact: [
            'Physical health: Business density is associated with lower rates of obesity, diabetes and cardiovascular disease in adults and older people.',
            'Mental health: Easy access to economic activity can contribute to improved mental health and happiness, reducing the risk of depression.',
            'Behaviour: Access to economic activity encourages physical activity and reduces sedentary behaviour.',
        ],
        how_to_measure: [
            'Retail per capita ratio',
            'Proximity of housing to economic activity',
            'Gross income per inhabitant',
            'Number of companies per unit area'
        ],
        action: [
            'Ensure space for local commerce in all neighbourhoods',
            'Incorporate weekly markets to complement commercial activity',
        ],
    },
    {
        id: 11,
        img: mobility,
        title: 'Street connectivity and intersection density',
        category: 'Connected Cities',
        definition: 'Street connectivity refers to the way streets are connected to each other, which determines how easy it is to move around.',
        description: [
            'A healthy city should avoid dead-end streets and distances greater than 120m between intersections. Different mobility networks should be interlinked, and pedestrians and cyclists should have priority at intersections.',
        ],
        health_impact: [
            'Physical health: Well connected streets are associated with improved obesity, BMI, and type 2 diabetes outcomes.',
            'Mental health: Good street connectivity is associated with improved cognitive function and perception of safety.',
            'Behaviour: Good street connectivity encourages physical activity and reduces sedentary behaviour.',
        ],
        how_to_measure: [
            'Number of intersections with 3 or more streets per km²'
        ],
        action: [
            'Ensure continuity of sidewalks',
            'Enable passageways through buildings in areas with low permeability',
        ],
    },
    {
        id: 12,
        img: mobility,
        title: 'Connection to points of interest',
        category: 'Connected Cities',
        definition: 'Direct connection to the places that people want to go, such as services, shops, parks and public transport routes.',
        description: [
            'A healthy city should ensure good connection between points of interest like public services, green spaces and commercial areas. Likewise, neighbourhoods should be well connected to each other and to the city centre.',
        ],
        health_impact: [
            'Physical health: Good connections are associated with reduced BMI, type 2 diabetes and cardiovascular disease. Good school connectivity is related to reduced childhood obesity.',
            'Mental health: Ease of access to places of interest is associated with improved rates of depression and cognitive function.',
            'Environmental health: Connectivity has a positive impact on noise pollution levels.',
            'Behaviour: Good connectivity increases rates of physical activity during leisure and travel.',
        ],
        how_to_measure: [
            'Perception surveys'
        ],
        action: [
            'Improve access to parks and natural areas',
            'Ensure access to the main public buildings',
        ],
    },
    {
        id: 13,
        img: mobility,
        title: 'Cyclability',
        category: 'Connected Cities',
        definition: `Cyclability refers to the length, structure, extension and quality of the city's bicycle lane network.`,
        description: [
            'A healthy city should ensure that the cycle network connects residential areas, different neighbourhoods, public services, green spaces and commercial areas. The cycle network should also be connected to a natural environment network.',
            'Priority should be given to continuing the existing network, eliminating interruptions and conflict with other modes of transport.',
            'To improve the quality of the cycle network, lane width, lighting and segregation from motorised traffic and pedestrians must be taken into account, as well as the presence of vegetation along the route.',
            'Adequate distribution of bicycle parking spaces throughout the city and provision in residential areas should also be considered.',
        ],
        health_impact: [
            'Physical health: Good cyclability is associated with improvements in BMI, obesity, premature mortality, birth outcomes, cardiovascular diseases, asthma and functional capacity.',
            'Behaviour: Good cyclability increases rates of physical activity during leisure and travel.',
        ],
        how_to_measure: [
            'Linear metres of cycle lanes',
            'Discontinuity points per km',
            'Perception survey'
        ],
        action: [
            'Define a basic structure of cycle lanes that is connected to the rest of the network',
            'Use marks or signals to reinforce the separation between motorised areas and cyclist areas',
            'Foresee spaces for parking bicycles along and at the end of the routes',
        ]
    },
    {
        id: 14,
        img: mobility,
        title: 'Walkability',
        category: 'Connected Cities',
        definition: `Walkability refers to the ability to move comfortably, safely and pleasantly around a city on foot.`,
        description: [
            'A healthy city should ensure quality pedestrian infrastructure provides access to public services from the houses. Pedestrian infrastructure should be sufficiently wide, separate from motorised traffic and maintain continuity at intersections, avoiding physical barriers and level changes.',
            'High quality, comfortable streets also include greenery, commercial activity at street level, sheltered spaces (from sun or rain) and resting places along the routes.',
        ],
        health_impact: [
            'Physical health: Neighbourhoods with higher walkability have reduced levels of obesity, BMI, incidences of type 2 diabetes, and cardiovascular disease. Walkability is also related to reduced premature mortality and improved perceived general health.',
            'Behaviour: Walkability is strongly related to increased physical activity and reduced sedentary behaviour. The perception of a walkable environment increases the likelihood of walking, especially in more socio-economically deprived areas.',
        ],
        how_to_measure: [
            '% of streets with pavements wider than 1.5m',
            'pedestrian crossing availability (% of total number of crossings)',
            'Perception survey'
        ],
        action: [
            'Design and promote specific walking routes: accessible, with guide maps, indications of distances, etc.',
            'Avoid elevated walkways and underpasses for pedestrians',
            'Provide benches, fountains and rest areas along the routes for longer journeys',
        ]
    },
    {
        id: 15,
        img: mobility,
        title: 'Public Transport',
        category: 'Connected Cities',
        definition: `Public transport refers to the structure, connectivity and availability of the public transport network.`,
        description: [
            'A healthy city should ensure that public transport has a clear structure and good coverage with a network that covers the main streets and facilitates access from all neighbourhoods.',
            'Bus stops and stations must be in convenient places, within walking distances of people’s homes (300m to the bus stop; 600m to the tram stop; 800m to the train stop). Stops should have adequate space, shelter and security; as well as connection to active travel (cycle and pedestrian) networks.',
        ],
        health_impact: [
            'Physical health: Availability of public transport is associated with lower BMI and reduced obesity rates.',
            'Mental health: Having access to the public transport network contributes to reducing the risk of depression, especially for women and older people, by increasing opportunities for mobility and socialising.',
            'Behaviour: Availability of public transport is strongly related to increasing physical activity and reducing sedentary behaviour.',
        ],
        how_to_measure: [
            '% of population with access to a public transport stop within 400m',
            'average distance to the nearest stop'
        ],
        action: [
            'Provide accessible links between the different modes of transport',
            'Place information and maps on public transport stops about nearby destinations (including distance, walking time, calories burned, etc.) to promote active mobility',
            'Create multi-modal hubs that incorporate parking areas to facilitate the change between transport modes (public transport and active mobility)',
        ]
    },
    {
        id: 16,
        img: mobility,
        title: 'Traffic',
        category: 'Connected Cities',
        definition: `Traffic refers to the density, type and speed of vehicles.`,
        description: [
            'A healthy city should implement measures to reduce the speed and volume of motorised traffic. These include restrictions for motorised vehicles in urban areas, alternative parking in city outskirts, minimising space for motorised private transport, and speed reduction measures.',
        ],
        health_impact: [
            'Physical health: Reducing traffic volume is directly related to reducing respiratory diseases (e.g asthma). Reducing vehicle speed is directly related to reducing accidents and falls.',
            `Mental health: Exposure to motor traffic noise and proximity to the main roads affects children's cognitive development, leading to attention deficit disorders.`,
            'Environmental health: Traffic reduction is directly related to improved air quality and reduced noise pollution.',
            'Behaviour: Physical activity is increased by the greater perception of safety in low-traffic environments.',
        ],
        how_to_measure: [
            'Average traffic speed',
            'Cars/day'
        ],
        action: [
            'Separate pedestrians from vehicles using furniture, trees, etc.',
            'Reduce the width of the roads, to promote lower speed',
            'Implement “park and ride” strategies, creating dissuasive parking facilities at the entrances to the city, linking to the public transport network',
        ]
    },
    {
        id: 17,
        img: mixticity,
        title: 'Availability of diverse public services',
        category: 'Complete neighbourhoods',
        definition: `Residential proximity to diverse public services, especially those related to healthcare, education, cultural and community centres.`,
        description: [
            'A healthy city should ensure residents have good access to health, social, educational and cultural services and facilities.',
            'Social services and facilities should be properly sized, based on population density, and distributed in a balanced way throughout the city. The concentration of facilities in one location should be avoided as this can lead to disused and unsafe areas at certain times.',
            'Certain services, especially school and health facilities, should be placed in proximity to green areas.'
        ],
        health_impact: [
            'Physical health: The proximity of housing to educational, cultural, social and health services is directly associated with improved general perception of health.',
            `Mental health: A wider variety of destinations close to home is associated with an increase in physical and mental well-being. It reduces factors such as stress, anxiety or depression, leading to a better quality of life and increased population happiness.`,
            'Behaviour: Proximity to different services is strongly related to increased physical activity and increased social interaction.',
        ],
        how_to_measure: [
            'Average distance to the different services',
            'Perception survey'
        ],
        action: [
            'Adapt urban planning regulations to promote mixed-use areas and a variety of activities (housing, offices, schools, trade, green areas, etc.), avoiding specialised areas with single activities',
        ]
    },
    {
        id: 18,
        img: mixticity,
        title: 'Availability of physical activity and sport infrastructure',
        category: 'Complete neighbourhoods',
        definition: `Residential proximity to indoor and outdoor physical activity and sport infrastructure.`,
        description: [
            'A healthy city should plan new sports facilities, ensuring they are appropriately sized based on population density and well distributed through the city. Housing should have access to sports facilities within a 300m radius.',
            'Sports infrastructure should be placed in some parks or public spaces, with good connections between them and to the network of natural paths.'
        ],
        health_impact: [
            'Physical health: The availability of sports facilities is significantly associated with reducing BMI.',
            `Mental health: There is also a relationship with improved mental health and happiness.`,
            'Behaviour: Sports facilities improve physical activity levels. Children living in neighbourhoods with a greater presence of sports amenities have higher levels of physical activity for leisure.',
        ],
        how_to_measure: [
            'Average distance to physical and sport services and infrastructure',
            'Perception survey'
        ],
        action: [
            'Promote the various uses of public space: leisure, sport, relaxation, meeting point, playground, etc.',
            'Promote temporary community use of unused spaces, such as school playgrounds outside of school hours',
        ]
    },
    {
        id: 19,
        img: mixticity,
        title: 'Availability of commerce and economic activity',
        category: 'Complete neighbourhoods',
        definition: `Residential proximity to recreational amenities, commerce, retail, shops and other economic activities.`,
        description: [
            'A healthy city should foster commercial use of street level units, encourage local commerce and ensure access to shops within a 10 minute walking distance from housing.'
        ],
        health_impact: [
            'Physical health: Proximity to shops and economic activity is associated with a reduction in BMI, obesity, type 2 diabetes and cardiovascular disease.',
            `Mental health: A wider presence of destinations close to home is associated with an increase in physical and mental well-being.  It reduces factors such as stress, anxiety or depression, leading to a better quality of life and increased population happiness.`,
            'Behaviour: In adults, a diversity of uses in the environment is related to higher levels of physical activity, as well as reduced sedentary behaviour.',
        ],
        how_to_measure: [
            'Perception survey'
        ],
        action: [
            'Provide commercial and work spaces in proximity to green spaces, parks, etc.',
            'Promote commercial activity and services around housing and office areas',
            'Promote the use of terraces and activities in certain public spaces to improve street activity',
        ]
    },
    {
        id: 20,
        img: mixticity,
        title: 'Availability of public open spaces and natural areas',
        category: 'Complete neighbourhoods',
        definition: `It refers to residential proximity to green areas and open public spaces. It takes into account the distance to green areas (proximity to green areas), rather than the amount of greenery in the city as a whole.`,
        description: [
            'A healthy city should ensure access from housing to green areas at a distance of 300-400m.',
            'Public spaces and green areas should be equitably distributed throughout the city, linked to each other via green or pedestrianised routes, and linked to health and educational facilities.',
        ],
        health_impact: [
            'Physical health: Proximity to green areas is directly associated with improved perceived general health, improved rates of respiratory disease, cardiovascular disease, premature mortality and birth outcomes.',
            `Mental health: Proximity to green spaces is associated with improvements in all indicators of mental health, especially depression, stress, cognitive function, emotional well-being, general mental health and happiness.`,
            'Environmental health: Increased availability of green space is associated with improved air quality and reduced noise pollution.',
            'Behaviour: Proximity to public spaces and natural areas is associated with higher rates of physical activity and increased social interaction.',
        ],
        how_to_measure: [
            'Average distance to public open spaces from homes and workplaces',
            'Average distance between green spaces',
            '% of population with a green space within 300m'
        ],
        action: [
            'Organise pedestrian-oriented activities in public space: street markets, days without cars, etc.',
            'Plan for different uses in a variety of climatic conditions',
            'Think about free spaces as an essential part of urban development',
            'Incorporate green areas in all neighbourhoods and ensure access to a green space within 300m of every home',
            'Link the network of green public spaces with the main social, educational and health facilities',
        ]
    },
    {
        id: 21,
        img: mixticity,
        title: 'Food environment',
        category: 'Complete neighbourhoods',
        definition: `A healthy food environment ensures access to healthy food in the immediate area.`,
        description: [
            'A healthy city should promote a healthy food environment, improving access and connection to municipal markets and implementing planning measures to support grocery stores and fresh-food shops in all the neighbourhoods.',
            'Urban planning can also limit fast food restaurants in certain environments and encourage the use of urban gardens or allotments.',
        ],
        health_impact: [
            'Physical health: A healthy food environment improves BMI and obesity.',
            `Mental health: A healthy food environment is related to improvements in general mental health.`,
            'Behaviour: In general, more food-related services, and a greater access to fresh and healthy products, is related to an improvement in food habits.',
        ],
        how_to_measure: [
            'Perception survey'
        ],
        action: [
            'Incorporate weekly farmers markets as a complement to local commerce',
            'Provide space for urban food growing',
            'Ensure the connection to local markets and improve their surrounding public space',
        ]
    },
    {
        id: 22,
        img: landscape,
        title: 'Green coverage',
        category: 'Green cities',
        definition: `Green coverage refers to the total amount of greenery in the city.`,
        description: [
            'A healthy city should increase green coverage and green visibility of all types of greenery including public green areas, private green areas, the street tree canopy, etc.',
            'Urban planning and landscaping must ensure the incorporation of greenery in the city. It is important to increase the presence of greenery and ensure its visibility. Measures should encourage green roofs, green facades, and greenery in private courtyards or gardens.',
        ],
        health_impact: [
            'Physical health: The increased presence of green in the city is related to improvements in almost all physical health indicators (general health, obesity, premature mortality, birth outcomes, type 2 diabetes, asthma and respiratory diseases), but it is particularly important for improving outcomes relating to cardiovascular diseases.',
            `Mental health: Increasing in green spaces is associated with improvements in all indicators of mental health, especially depression, stress, cognitive function, emotional well-being, general mental health and happiness.`,
            'Environmental health: Increased presence of green is associated with improved air quality and reduced noise pollution, and is particularly relevant for improved biodiversity.',
            'Behaviour: Increased presence of green is associated with higher rates of physical activity and increased social interaction.',
        ],
        how_to_measure: [
            'Square metres of green space per inhabitant',
            'Normalised difference vegetation index (NDVI)',
            'Perception survey',
        ],
        action: [
            'Implement urban planning regulations to require a minimum amount of greenery for all development projects',
            'Encourage the introduction of greenery in private spaces, such as indoor gardens, rooftops, etc.',
        ]
    },
    {
        id: 23,
        img: landscape,
        title: 'Blue spaces',
        category: 'Green cities',
        definition: `Blue spaces refers to the presence of water, whether natural such as rivers, canals, lakes or sea, or the incorporation of water as an element of play and design in public spaces.`,
        description: [
            'A healthy city should improve and increase proximity to and visibility of blue spaces',
        ],
        health_impact: [
            'Physical health: The presence of water and blue spaces is particularly important to reducing heat stress.',
            `Mental health: The presence of water is associated with an improvement in indicators of depression and anxiety.`,
            'Behaviour: Blue spaces are associated with increased social interactions.',
        ],
        how_to_measure: [
            'Average distance from homes/workplaces to water areas',
        ],
        action: [
            'Integrate blue spaces in to the design of public spaces',
        ]
    },
    {
        id: 24,
        img: landscape,
        title: 'Green space diversity',
        category: 'Green cities',
        definition: `Different types of green areas should respond to the needs of the neighbourhoods and city, including different locations, sizes, structure and vegetation.`,
        description: [
            'A healthy city should provide varied types and typologies of green areas according to different uses and needs (neighbourhood parks, varied planting, wild areas, large-scale city and regional green areas etc.). All neighbourhoods should have a green space (recommended size >0,3Ha) within 300m of homes; and ensure access to a city-scale green space (recommended size >5Ha) within 2km of homes.',
            'Different types of vegetation in green areas should be encouraged, not only grasses and trees, but all types of shrubs and bushes, which shelter a diversity of animal and plant species. A rich and diverse ecosystem reduces the need for pest control, enabling ecological management of green areas.',
        ],
        health_impact: [
            'Physical health: Green space diversity is related to improvements in premature mortality, birth outcomes, type 2 diabetes, cardiovascular and respiratory diseases.',
            `Mental health: Green space diversity is associated with improved indicators of general mental health, stress and depression.`,
            'Environmental health: Green space diversity is directly associated with improvements in biodiversity.',
            'Behaviour: Green space diversity is associated with increased physical activity.',
        ],
        how_to_measure: [
            'Count of trees and plant varieties',
        ],
        action: [
            'Design different types of green areas according to different uses',
            'Create or improve urban forests',
            'Ensure a variety of types of greenery',
        ]
    },
    {
        id: 25,
        img: landscape,
        title: 'Continuity of green infrastructure',
        category: 'Green cities',
        definition: `This refers to the physical and visual continuity between the different green areas that make up the city’s green infrastructure, whether they are public green areas, semi-public green areas, street vegetation or the natural environment surrounding the city.`,
        description: [
            'A healthy city should improve the continuity of green infrastructure, by planning green routes cto connect green and blue areas, considering the connection between urban green areas and the wider natural environment, incorporating vegetation in all new streets, and planning wildlife passages at critical points.',
        ],
        health_impact: [
            'Physical health: Continuous green infrastructure is associated with improvements in cardiovascular and respiratory diseases.',
            'Mental health: Green continuity has a particularly strong influence on improving general mental health indicators.',
            'Environmental health: Green continuity is directly associated with an improvement in biodiversity and noise pollution.',
            'Behaviour: Green continuity is associated with increased physical activity.',
        ],
        action: [
            'Create green corridors as connections between different green areas',
            'Create a green belt around the city',
            'Place green areas along the main pedestrian paths',
        ]
    },
    {
        id: 26,
        img: landscape,
        title: 'Urban landscape',
        category: 'Green cities',
        definition: `A healthy city should improve the urban landscape and general amenities in public open spaces (aesthetics, urban furniture, maintenance, lighting, etc.). Children's play areas should be distributed throughout the different public spaces in the city. The design of public space should take into account safety aspects and provide appropriate spaces for social interaction. Public spaces must take inclusivity and accessibility into account.`,
        health_impact: [
            'Physical health: An improved urban landscape is associated with improved general health and obesity, especially with a lower rate of accidents, falls and injuries and an improved functional capacity.',
            'Mental health: An improved urban landscape is associated with improved perception of safety and happiness.',
            'Behaviour: People who perceive their neighbourhoods to be aesthetically attractive, with well-maintained street furniture and amenities, adequate signage, and good lighting, are more likely to engage in outdoor physical activity in leisure or transport time, regardless of age and, in turn, experience increased social interaction.',
        ],
        how_to_measure: [
            'Perception survey',
        ],
        action: [
            'Promote partnerships with organisations and businesses to sponsor and maintain parks and gardens',
            `Think about the different possible uses for open spaces: sports, urban gardens,children's playground, meeting point ...`,
            'Design parks considering the use and preferences of the local population and age groups',
        ]
    },
    {
        id: 27,
        img: housing,
        title: 'Quality of housing',
        category: 'Quality homes',
        definition: `Quality housing refers to the physical aspects necessary in order to achieve a liveable environment, but also to ensuring equitable access to housing.`,
        description: [
            'A healthy city should take measures to improve the quality of housing (such as minimum size, ventilation, materials, orientation, etc.), as well as grant access to outdoor space or to community spaces (like rooftops or shared gardens).',
            'In addition, healthy urban planning must provide for measures to ensure equitable access to housing and to distribute social housing homogeneously throughout the neighbourhoods.',
        ],
        health_impact: [
            'Physical health: Housing quality is especially related to an improvement in respiratory diseases',
            'Mental health: Access to and quality of housing is a fundamental aspect of mental health, and especially related to improvements in depression, quality of life and general mental health',
        ],
        how_to_measure: [
            'Average floor area of homes',
            'Availability of access to housing',
        ],
        action: [
            'Incorporate access to outdoor spaces (both private and shared) and community spaces in housing developments',
            'Ensure equitable access to housing and that social housing is homogeneously distributed throughout the area',
        ]
    },
    {
        id: 28,
        img: housing,
        title: 'Energy efficiency measures',
        category: 'Quality homes',
        definition: `Energy efficiency refers to the different measures related to energy savings and efficiency within homes (heating, insulation, etc.).`,
        description: [
            'A healthy city should improve the energy efficiency of housing including regulations related to energy efficiency and encouraging the use of energy cogeneration measures.',
            'Urban planning can include minimum parameters related to energy certification, and encourage the use of vegetation (for cooling) or other passive measures around buildings.',
        ],
        health_impact: [
            'Physical health: Energy efficiency is especially related to an improvement in respiratory diseases',
            'Mental health: Energy efficiency is associated with improved general mental health and perception of quality of life',
        ],
        how_to_measure: [
            'Energy consumption',
        ],
        action: [
            'Include regulations and guidelines on energy saving and energy efficiency of buildings',
            'Encourage the use of green elements in buildings to act as passive cooling measures',
        ]
    }
]


export { determinants_description };


