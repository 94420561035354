import React, {useState, useEffect} from "react";
import styles from "./CustomPagination.module.css"
import {CaretLeftOutlined, CaretRightOutlined} from "@ant-design/icons"


const CustomPaginationComp = (props) => {
    
    const [currentPage, setCurrentPage] = useState(props.currentPage);
    const [totalPages, setTotalPages] = useState(props.pageSize);
    const [newPageAdded, setNewPageAdded] = useState(props.newPageAdded);
    const [newPageInitiated, setNewPageInitiated] = useState(props.newPageInitiated);

    const [pageDeleted, setPageDeleted] = useState(props.pageDeleted);
    const [displayPageNumbers, setDisplayPageNumbers] = useState([1,2,3,4,5]);

    const prevButtonClickHandler = () => {
        if(currentPage > 1 && false === newPageInitiated) {
            let revisedCurrentPage = -1;
            if(true === pageDeleted) {
                //setCurrentPage((page) => page-1);
                revisedCurrentPage = currentPage;
            }
            else {
                setCurrentPage((page) => page-1);
                revisedCurrentPage = currentPage - 1;
            }
            
            //const matchedNumber = displayPageNumbers.find(n => n==(currentPage-1));
            const matchedNumberIndex = displayPageNumbers.indexOf(revisedCurrentPage);
            //if(undefined === matchedNumber) {
            if(-1 === matchedNumberIndex) {
                //setDisplayPageNumbers((numberArr) => [(currentPage-1), ...numberArr.slice(0, numberArr.length-1) ]);
                let pageNumberArr = [];
                let counter=1;
                if(revisedCurrentPage < 5) {
                    revisedCurrentPage = 5;
                }
                for(let i=(revisedCurrentPage-4); counter <=5; i++) {
                    counter++;
                    pageNumberArr.push(i);
                }

                setDisplayPageNumbers([...pageNumberArr]);

            }
        }
    }

    const nextButtonClickHandler = () => {
        if(currentPage < totalPages && false === newPageInitiated) {
            let revisedCurrentPage = -1;
            if(true === newPageAdded) {
                setCurrentPage((page) => totalPages);
                revisedCurrentPage = totalPages;
            }
            else {
                setCurrentPage((page) => page+1);
                revisedCurrentPage = currentPage+1
            }
            
            //const matchedNumber = displayPageNumbers.find(n => n==(currentPage+1));
            const matchedNumberIndex = displayPageNumbers.indexOf(revisedCurrentPage);
            if(-1 === matchedNumberIndex) {
                //setDisplayPageNumbers((numberArr) => [...numberArr.slice(1), (currentPage+1)]);
                //const newData = [...displayPageNumbers.slice(1), (currentPage+1)];
                let pageNumberArr = [];
                let counter=1;
                for(let i=(revisedCurrentPage+4); counter <=5; i--) {
                    counter++;
                    pageNumberArr.push(i);
                }

                setDisplayPageNumbers([...pageNumberArr.reverse()]);
            }
        }
    }


    useEffect(() => {
        if(undefined !== props.onClick && false === newPageInitiated) {
            props.onClick(currentPage);
        }        
    }, [currentPage]);

    useEffect(() => {
        if(true === newPageAdded) {
            nextButtonClickHandler();
        }
    }, [newPageAdded]);

    useEffect(() => {
        if(true === pageDeleted) {
            prevButtonClickHandler();
        }
    }, [pageDeleted]);
    //

    useEffect(() => {
        setCurrentPage(props.currentPage);
    }, [props.currentPage]);

    useEffect(() => {
        setTotalPages(props.pageSize);
    }, [props.pageSize]);

    useEffect(() => {
        setNewPageAdded(props.newPageAdded);
    }, [props.newPageAdded]);

    useEffect(() => {
        setNewPageInitiated(props.newPageInitiated);
    }, [props.newPageInitiated]);

    useEffect(() => {
        setPageDeleted(props.pageDeleted);
    }, [props.pageDeleted]);
    return (
        <div className={styles.container}>
            <div className={styles.prevButton} onClick={prevButtonClickHandler}><CaretLeftOutlined className={`${styles.prevButtonIcon} ${currentPage <= 1 && styles.disabledIcon} ${props.newPageInitiated && styles.disabledIcon}`} /></div>
            {
                displayPageNumbers.map((pageNumber)=> {
                    return (
                        <div key={pageNumber}
                            className={`${styles.pageNumber} ${totalPages > 0 && currentPage === pageNumber && styles.activePage} ${pageNumber > totalPages ? styles.disabledPage : ""} ${props.newPageInitiated && styles.disabledIcon}`}
                            onClick={(event) => pageNumber <= totalPages && false === props.newPageInitiated ? setCurrentPage(pageNumber) : null}
                        >
                            {pageNumber}
                        </div>
                    );
                })
            }
            <div className={styles.nextButton} onClick={nextButtonClickHandler}><CaretRightOutlined className={`${styles.nextButtonIcon} ${currentPage >= totalPages && styles.disabledIcon} ${props.newPageInitiated && styles.disabledIcon}`}/></div>
        </div>
    )
}
export default CustomPaginationComp;