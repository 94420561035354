import styles from './ResultItem.module.css'
import { useState } from 'react'
import modalStyles from './Popup.module.css'
import { Button, Modal } from 'antd';
import { InfoOutlined } from '@ant-design/icons';
import { determinants_description } from '../../../../../assets/text/urban-determinants.js'
const PopUp = ({ visible, setVisible, id }) => {
    return (
        <Modal
            visible={visible}
            onCancel={() => setVisible(state => !state)}
            footer={null}
            width={750}
        >
            {
                determinants_description.map((item, idx) => {
                    return (
                        <>
                            {item.id === id &&
                                <div>
                                    <div className={modalStyles.header}>
                                        <img src={item.img} />
                                        <div>
                                            <div className={modalStyles.title}>{item.title}</div>
                                            <div className={modalStyles.category}>{item.category}</div>
                                        </div>
                                    </div>
                                    <div>{item.definition}</div>
                                    <ul>
                                        {
                                            item?.description?.map((par, idx) => <li key={idx}>{par}</li>)
                                        }
                                    </ul>
                                    <div className={modalStyles.health_impact_wrapper} >
                                        <div className={modalStyles.health_impact_title} >Health impact</div>
                                        <ul className={modalStyles.health_impact_items}>
                                            {
                                                item?.health_impact?.map((par, idx) => <li key={idx}>{par}</li>)
                                            }
                                        </ul>
                                    </div>
                                    <div className={modalStyles.how_to_measure_wrapper} >
                                        <div className={modalStyles.health_impact_title} >How to measure</div>
                                        <ul className={modalStyles.health_impact_items}>
                                            {
                                                item.how_to_measure?.map((par, idx) => <li key={idx}>{par}</li>)
                                            }
                                        </ul>
                                    </div>
                                    <div className={modalStyles.how_to_measure_wrapper} >
                                        <div className={modalStyles.health_impact_title} >Actions</div>
                                        <ul className={modalStyles.health_impact_items}>
                                            {
                                                item.action?.map((par, idx) => <li key={idx}>{par}</li>)
                                            }
                                        </ul>
                                    </div>
                                </div>
                            }
                        </>
                    )
                })
            }
        </Modal>
    )
}

export default function ResultItem({ label, color, score, showLabels }) {
    const [visible, setVisible] = useState(false)
    const [id, setId] = useState(false);
    const handleMenu = (id) => {
        setVisible(state => !state);
        setId(id)
    }
    return (
        <div className={styles.result_wrapper}>
            <div className={styles.result_label} style={{ backgroundColor: color }}>{label}</div>
            <div className={styles.result_scores_wrapper}>
                {score?.map((item, idx) => {
                    return (
                        <div className={styles.result_score} key={idx}>
                            <div style={{ flexGrow: 1 }}>
                                <div style={{ display: 'flex', flexGrow: 1 }}>{item.label} </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ height: 25, transition: 'all 0.35s ease ', backgroundColor: color, width: item.value + '%' }} className={styles.result_bar}>
                                        {showLabels && <div style={{ fontSize: '10px', fontWeight: 600, padding: '0px 5px', fontSize: 14, }}>  {Math.trunc(item.value * 100) / 100}%</div>}
                                    </div>
                                    <Button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 }} onClick={() => handleMenu(item.id)} icon={<InfoOutlined style={{ paddingRight: 2 }} />} size={'small'} />

                                </div>
                            </div>


                        </div>
                    )
                })}
                <PopUp visible={visible} setVisible={setVisible} id={id} />
            </div>
        </div >
    )
};