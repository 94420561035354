import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppRoutes from "./appRoutes";
import HeaderComp from "./main/header";
import styles from "./Layout.module.css";
import API from '../../services/API.js';
import { ENDPOINTS } from '../../services/endpoints.js';
import * as staticDataActions from "../../redux/actions/staticDataActions";
import { notification } from 'antd';
import Footer from '../Layout/main/footer/Footer.js';

const openNotification = (type, title, msg) => {
    notification[type]({
        message: title,
        description:
            msg,
    });
};

export default function Layout(props) {
    const protocol = window.location.protocol; // if http protol
    const domain = window.location.host; // domain
    const dispatch = useDispatch();
    const isUser = useSelector(state => state.application.userLogin)
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [loadingMessage, setLoadingMessage] = useState("Loading...");

    const [cityInfoLoaded, setCityInfoLoaded] = useState(false);
    const [scoresLoaded, setScoresLoaded] = useState(false);
    const [scoreItemsLoaded, setScoreItemsLoaded] = useState(false);

    const getDomainInfo = () => {
        const domainName = window.location.hostname.substring(0, window.location.hostname.indexOf("."));
        //const domainName = "thess";
        return [domainName];
    }
    const [domainName] = getDomainInfo(); // for which city this website should be rendered

    const descriptionUpdateHandler = (descriptionInfo, cityId) => {

        updateCityDescription(descriptionInfo, cityId);
    }
    const fetchStaticData = async () => {
        setIsLoading(true);
        setLoadingMessage("Initializing...");
        setError("");
        try {
            //const token = "sometoken";
            await dispatch(staticDataActions.fetchCityInfo(domainName));
            setCityInfoLoaded(true);

            await dispatch(staticDataActions.fetchScores());
            setScoresLoaded(true);

            await dispatch(staticDataActions.fetchScoreItems());
            setScoreItemsLoaded(true);

            setIsLoading(prevState => !prevState);
        }
        catch (err) {
            setError(err.message);
            setIsLoading(false);
            setLoadingMessage("");
        };
    };

    const updateCityDescription = async (description, cityId) => {
        setIsLoading(true);
        setLoadingMessage("Initializing...");
        setError("");
        try {
            //const token = "sometoken";
            await dispatch(staticDataActions.updateCityDescription(description, cityId));
            setIsLoading(false);
        }
        catch (err) {
            setError(err.message);
            setIsLoading(false);
            setLoadingMessage("");
        };
    };

    useEffect(() => {
        if (isUser) {
            fetchStaticData();
        }
    }, [isUser])

    const staticDataLoaded = cityInfoLoaded && scoresLoaded && scoreItemsLoaded;

    const [cityExists, setCityExists] = useState(false)
    useEffect(() => {
        //let domain = 'thess'.toLowerCase()
        const domain = window.location.hostname.substring(0, window.location.hostname.indexOf("."));

        API.get(`${ENDPOINTS.API_CHECK_CITY}/${domain}`)
            .then(res => {
                setCityExists(res.data.isExists)
                if (!res.data.isExists) {
                    openNotification('error', 'Project not found', 'City domain does not exist, redirecting...')
                    setTimeout(() => {
                        window.location.replace('https://app.healthycitiesapp.com/');
                    }, 5000)
                }
            })
            .catch(err => console.log(err.response))
    }, [])
    return (
        <div className={`${styles.layout} `}>
            {/* header component - get city info, score, scoreItems */}
            <HeaderComp isUser={isUser} onDescriptionUpdate={descriptionUpdateHandler} />
            {/* show if error has been raised */}
            {/* <ShowErrorIfAny error={error} /> */}
            {/* spinner when loading */}
            {/* <ShowSpinnerIfBusy loading={isLoading} loadingMessage={loadingMessage} className={styles.loading} /> */}
            {/* app routes */}
            {cityExists && < AppRoutes staticDataLoaded={staticDataLoaded} />}
            <Footer />
            {/* footer */}
        </div>
    )
};
