export const ENDPOINTS = {
    API_FETCH_CITY_INFO: "/city",
    API_FETCH_SCORES: "/score",
    API_FETCH_SCORE_ITEMS: "/item",
    API_FETCH_PLANNING_ACTIONS: "/plan/city",
    API_FETCH_CHART_DATA: "/chart/city",
    API_UPDATE_PLANNING_ACTIONS: "/plan",
    API_ADD_PLANNING_ACTION: "/plan",
    // HS - V2
    API_USER_LOGIN: '/login',
    API_SAVE_INDICATORS: '/indicatorCheck',
    API_FETCH_STATS: '/item-indicator/stats',
    API_FETCH_INDICATORS: '/indicatorCheck',
    API_CHECK_CITY: "/city/domain",
    VALIDATE_TOKEN: '/token/validate',
    API_FETCH_ACTIONS_STATUS: '/city/action/hidden'
}

