import React, { useEffect, useState } from 'react';
import InputComponent from './inputComponent/InputComponent.js';
import OutputComponent from './outputCompoonent/OutputComponent.js';
import styles from './PlanningUrban.module.css'
import axios from 'axios';
import { useSelector } from 'react-redux'
import { ENDPOINTS } from '../../../../services/endpoints';
import { useStaticData } from "../../../../custom-hooks/appHooks";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, notification } from 'antd';
import API from '../../../../services/API.js';
import './styles.scss'

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 105,
            color: "#fd7d00"
        }}
        spin
    />
);

const openNotification = (type, title, msg) => {
    notification[type]({
        message: title,
        description:
            msg,
    });
};

export default function Index() {
    const cityId = useSelector(state => state.staticData.cityInfo.cityId)
    const [associations, setAssociations] = useState(null);
    const [backenedCheckboxes, setBackenedCheckboxes] = useState(null);
    const [state, setState] = useState(null);
    const static_data = useStaticData();
    const [nominator, setNominator] = useState();
    const [checkboxes, setCheckboxes] = useState({})
    const [output, setOutput] = useState(null)
    // 1 - call services
    useEffect(() => {
        if (cityId) {
            fetchData(cityId).then(res => {
                setAssociations(res?.associations)
                setBackenedCheckboxes(res?.backenedCheckboxes)
            })
        }
    }, [cityId])

    // 2 - create input object for to render
    useEffect(() => {

        if (backenedCheckboxes && associations && static_data[2].length) {
            // 2 - create input object to render
            let res = createAssociations(associations, backenedCheckboxes, static_data[2]);
            setState(res)
            setCheckboxes(res.checks)
            setNominator(res.nominator)

        }
    }, [backenedCheckboxes, associations, static_data[2]])

    // runs on each re-render. Computes the values for the health-planning bars
    useEffect(() => {
        // check if services and state initialized
        if (state !== null) {
            let computed_nominator = { ...nominator }
            for (let i = state.inputKeys[0]; i < state.inputKeys.length + 1; i++) {
                if (checkboxes[i]) {
                    for (let k = 0; k < associations[i].length; k++) {

                        computed_nominator[associations[i][k].itemId]++
                    }
                }
            }
            let denom = Object.values(checkboxes).filter(item => item === true).length
            // create output
            let colors = ['#e0b7e6', '#c6c9e8', '#84d3c4', '#b0df83', '#d3e954']
            let outputObj
            let all_scores = state.output.map(category => {
                let res = static_data[2].map((item, idx) => {
                    if (item.name.substring(
                        item.name.indexOf("[") + 1,
                        item.name.lastIndexOf("]")
                    ) === category) {

                        return {
                            label: item.name.split('[')[0],
                            id: item.itemId,
                            value: denom ? (computed_nominator[item.itemId] / denom) * 100 : 0,
                        }
                    }
                })
                return res.filter(item => item !== undefined)
            })
            outputObj = state.output.map((category, idx) => {
                return {
                    title: category,
                    color: colors[idx],
                    score: all_scores[idx]
                }
            })
            setOutput(outputObj)
        }
    }, [checkboxes])

    const selectAll = () => {
        const obj = {};
        state.inputKeys.forEach(element => {
            obj[element] = true;
        });
        setCheckboxes(obj)


    }
    const clearAll = () => {
        const obj = {};
        state.inputKeys.forEach(element => {
            obj[element] = false;
        });
        setCheckboxes(obj)

    }
    const updateCheckboxes = () => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        };
        let test = {}

        backenedCheckboxes.forEach(item => test[item.healthIndicator.healthIndicatorId] = item.id)
        let payload = state.inputKeys.map(key => {

            return {
                id: test[key],
                checked: checkboxes[key],
                healthIndicatorId: key,
                cityId: cityId
            }
        })

        API.put(ENDPOINTS.API_SAVE_INDICATORS, payload, config)
            .then(res => {
                if (res.status === 200) {
                    openNotification('success', 'Action complete', 'Changes saved successfully')
                }
            })
            .catch(err => {
                openNotification('error', 'Action failed', 'Changes were not saved. If this error persists, please contact the administrator.')

            });
    }

    return (
        <div style={{ position: 'relative', minHeight: '600px' }}>
            <div className={styles.mobileIncompatible}>
                Sorry! Mobile devices are not supported in the current version of Healthy Cities Generator. Please use Desktop/Laptop preferably using Google Chrome browser.
            </div>
            <div className={styles.app_body}>

                {
                    state === null
                        ?
                        <Spin indicator={antIcon} style={{ position: 'absolute', top: '50%', left: '50%', transform: "translate(-50%,-50%)" }} />
                        :
                        <>
                            <InputComponent data={state.inputObj} setCheckboxes={setCheckboxes} checkboxes={checkboxes} selectAll={selectAll} clearAll={clearAll} updateCheckboxes={updateCheckboxes} />
                            <OutputComponent data={output} />
                        </>
                }
            </div>
        </div>
    )
}

const createAssociations = (data, backendCheckboxes, outputCategories) => {
    /*
        This function is called when component is mounted. Does not tigger on re-render.
        Creates the relationship between the 30 health indicators and the 20 linked urban determinants of health.
    */


    let inputObj
    let colors = ['#ffd966', '#f9cb9c', '#ff964f', '#ff8761', '#ff5621']
    let inputKeys = [...Object.keys(data)].sort(function (a, b) { return a - b }) // get input items ids 1-30
    let categories = Object.keys(data).map(idx => data[idx][0].categoryName).filter((x, i, a) => a.indexOf(x) === i) // create unique categories for output

    // create items for each category
    let count = -1
    let all_children = categories.map(category => {
        let res = inputKeys.map(key => {
            if (data[key][0].categoryName === category) {
                count++;
                return {
                    label: data[key][0].healthIndicatorDescription,
                    id: inputKeys[count], //data[idx][0].itemId
                }
            }
        })
        return res.filter(item => item !== undefined)
    })

    // create arr. of objects to render 
    inputObj = categories?.map((category, idx) => {
        return {
            name: category.toLowerCase(),
            color: colors[idx],
            classType: `customCheckbox-${idx}`,
            children: all_children[idx]
        }
    })

    // *** ASSOCIATION & SCORRING***
    //--------------------------------------------------------------------------------

    // 1. get association 
    let associations = Object.keys(data).map(key => {
        return Object.keys(data[key]).map(item => data[key][item].itemId)
    })

    // 2. count totals
    let totals = {}
    let nominator = {}
    let outputKeys = [...new Set(associations.flat(1))].sort(function (a, b) { return a - b })

    for (const key of outputKeys) {
        totals[String(key)] = 0
        nominator[String(key)] = 0
    }

    for (let i = 0; i < associations.length; i++) {
        for (let k = 0; k < associations[i].length; k++) {
            totals[associations[i][k]]++
        }
    }


    //--------------------------------------------------------------------------------
    // output keys: 9-28
    // input  keys: 1-30
    //--------------------------------------------------------------------------------

    // append values from backened
    let checks = {}
    for (const key of inputKeys) {
        checks[String(key)] = false
    }
    if (backendCheckboxes.length === 0) {
        //console.log('backend empty....')
    } else {
        for (let k = 0; k < backendCheckboxes.length; k++) {
            checks[backendCheckboxes[k].healthIndicator.healthIndicatorId] = backendCheckboxes[k].checked
        }
    }
    // create output obj to render
    let output = [...new Set(Object.keys(outputCategories)?.map(idx => outputCategories[idx].name.substring(
        outputCategories[idx].name.indexOf("[") + 1,
        outputCategories[idx].name.lastIndexOf("]")
    )))]
    return { totals: totals, checks: checks, nominator: nominator, associations: associations, inputObj: inputObj, output: output, inputKeys: inputKeys, outputKeys: outputKeys }

}
const fetchData = async (cityId) => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    };

    let data = await axios.all([
        API.get(ENDPOINTS.API_FETCH_STATS, config),
        API.get(ENDPOINTS.API_FETCH_INDICATORS + '/' + cityId, config)
    ])
        .then(axios.spread((data1, data2) => {
            return { associations: data1.data, backenedCheckboxes: data2.data }
        }))
        .catch((err1, err2) => {
            openNotification('error', 'Something went wrong', 'An internal error occured. If this error persists, please connect with administrator.')
        }
        );
    return data
}