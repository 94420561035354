import React, {useState, PureComponent, useEffect} from "react";
import { Symbols, Surface, BarChart, Bar, XAxis, YAxis, CartesianGrid, Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip, PieChart, Pie, Cell, Label } from 'recharts';
import styles from "./SpiderChart.module.css";
import { ITEM_COLOR_MAPPING_SPIDER, CATEGORY_COLOR_MAPPING } from "../../../common/utils";

const axisMouseOverHandler =(e) => {
  //console.log("eee", e)
}

const CustomTick = ({ payload, x, y, textAnchor, stroke, radius, fill, value }) => {
  //console.log("payload", payload)
  return (
    <>
    <text 
        x={x} 
        y={y} 
        dy={-4}
        fontSize='10' 
        fontFamily='sans-serif'
        fill={getRandomColor()}
        textAnchor="middle">{payload.value}
    </text>
    </>
    )
  }

const getRandomColor = () => {  
  return "#" + ((1<<24)*Math.random() | 0).toString(16);
}

const CustomTooltip = ({ active, payload, label, onMouseOver, onMouseOut }) => {
  
  if (active && payload && payload.length) {

    if(undefined !== onMouseOver) {
      onMouseOver(payload[0].payload.axis)
    }    

    return (
      <div className={styles.tooltipContainer} >
          <div className={styles.title} >{`${payload[0].payload.title}`}</div>          
          <div className={styles.item}>
            {
              undefined !== payload[0] && null !== payload[0]
              ?
              <div className={styles.keyValue}>
                <div className={`${styles.key} ${styles.totalAction}`} >{payload[0].name}</div>
                <div className={`${styles.value}`} >{parseFloat(payload[0].value).toFixed(2)}</div>
              </div>
              :
              ""
            }

            {
              undefined !== payload[1] && null !== payload[1]
              ?
              <div className={styles.keyValue}>
                <div className={`${styles.key} ${styles.currentAction}`} >{`${payload[1].name}`}</div>
                <div className={`${styles.value}`}>{parseFloat(payload[1].value).toFixed(2)}</div>
              </div>
              :
              ""
            }

          </div>
      </div>
    );
  }
  else {
    if(undefined !== onMouseOut) {
      onMouseOut()
    }    
  }

  return null;
};

const renderColorfulLegendText = (value, entry) => {
  const { color } = entry;
  return <span style={{ color }}>{value}</span>;
};

const renderBarChartLegendContent = (props) => {
  return props.payload
  .filter(entry => entry.dataKey !== "totalScore")
  .map((entry, idx) => {
    return (
      <div key={idx}>
        <Surface width={10} height={10} viewBox={"0 0 10 10"}>
          <Symbols cx={5} cy={5} type="square" size={50} fill={entry.color} />
        </Surface>
        {entry.dataKey}
      </div>
    )
  })
}

//tick={{angle:0, fontSize:8,fill:"black", fontWeight:"bold"}}
export const SpiderChartComp = (props) => {

  const [innerRadiusData, setInnerRadiusData] = useState("");
  const [outerRadiusData, setOuterRadiusData] = useState("");


  const angleMouseClickHandler = (d) => {
    //console.log("click",d)
  }
  const angleMouseOverHandler = (d) => {
    if(undefined !== props.onAngleMouseOver) {
      props.onAngleMouseOver(d.value)
    }    
  }
  const angleMouseOutHandler = () => {
    if(undefined !== props.onAngleMouseOver) {
      props.onAngleMouseOut()
    }    
  }

  const axisLineMouseOverHandler = (d) => {
    if(undefined !== props.onAxisLineMouseOver) {
      props.onAxisLineMouseOver(d)
    }    
  }
  const axisLineMouseOutHandler = () => {
    if(undefined !== props.onAxisLineMouseOut) {
      props.onAxisLineMouseOut()
    }    
  }




  useEffect(() => {
    setInnerRadiusData(props.innerRadius);
    setOuterRadiusData(props.outerRadius);  
  }, [props.innerRadius, props.outerRadius])

  

  return (
    <>
    <ResponsiveContainer width="100%" height="100%">      
      <RadarChart id="rc" className={styles.radarChart} outerRadius="90%" data={props.data} >
        <defs>
          <linearGradient
            id="totalActionsSpider"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
            spreadMethod="reflect"
          >
            <stop offset="0" stopColor="#ff9c64" />
            <stop offset="1" stopColor="orange" />
          </linearGradient>        
          <linearGradient
            id="currentActionSpider"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
            spreadMethod="reflect"
          >
            <stop offset="0" stopColor="#ff8365" />
            <stop offset="1" stopColor="#ff8365" />
          </linearGradient>        
        </defs>                

        <PolarGrid />
        <PolarAngleAxis onMouseOver={angleMouseOverHandler} onMouseOut={angleMouseOutHandler} onMouseClick={angleMouseClickHandler} dataKey="axis" tick={{angle:0, fontSize:8,fill:"black", fontWeight:"bold"}} />
        <PolarRadiusAxis tick={{fontSize:0,fill:"maroon", fontWeight:"bold"}} angle={0} domain={[0, 4]} orientation={"middle"} scale="sequential" onMouseOver={axisMouseOverHandler}/>

        <Radar dot={false}  activeDot={true}  legendType="square" name="Total Actions" dataKey="totalActions" stroke="url(#totalActionsSpider)" fill="url(#totalActionsSpider)" fillOpacity={0.8}/>
        {
          undefined !== props.currentActionPlanningName && null !== props.currentActionPlanningName
          ?
          <Radar dot={false} activeDot={true} legendType="square" name={props.currentActionPlanningName} dataKey={props.currentActionPlanningName} stroke="url(#currentActionSpider)" fill="url(#currentActionSpider)" fillOpacity={0.7}/>        
          :
          ""
        }        


        <Tooltip content={<CustomTooltip onMouseOver={axisLineMouseOverHandler} onMouseOut={axisLineMouseOutHandler}/>} />
        
        <Legend formatter={renderColorfulLegendText} wrapperStyle={{fontWeight:"bold",fontSize:"8px",lineHeight:"15px"}} layout="vertical" verticalAlign="top" height={25} align={"right"}/>       
      </RadarChart>
    </ResponsiveContainer>

        
    <ResponsiveContainer width="100%" height="100%" >
    <PieChart id="pc" 
        style={{ 
          position:"relative",
          top:"-100%"
        }}        
        
    >
      <Pie
        id="p"
        data={props.data}
        innerRadius={innerRadiusData}
        outerRadius={outerRadiusData}
        fill="#8884d8"
        dataKey="fullValue"
        paddingAngle={4}
        startAngle={-7}              
      >
        {props.data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={ITEM_COLOR_MAPPING_SPIDER[index % Object.keys(ITEM_COLOR_MAPPING_SPIDER).length].color} />
        ))}
      </Pie>
    </PieChart>
    </ResponsiveContainer>

    </>
  )
}

export const StackedBarChartComp = (props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={props.data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <defs>
          <linearGradient
            id="totalActionsBar"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
            spreadMethod="reflect"
          >
            <stop offset="0" stopColor="green" />
            <stop offset="1" stopColor="lightgreen" />
          </linearGradient>        
          <linearGradient
            id="currentActionBar"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
            spreadMethod="reflect"
          >
            <stop offset="0" stopColor="red" />
            <stop offset="1" stopColor="orange" />
          </linearGradient>        
        </defs>                

        <CartesianGrid strokeDasharray="3 3" />
        <XAxis interval={0} tick={{fontSize:"7px"}} dataKey="axis" label={{ fontWeight:"bold", fontSize:"12px", value: 'List of Health Indicators', angle: 0, position: 'insideBottom', offset:0 }}/>
        <YAxis tick={{fontSize:"10px"}} label={{ fontWeight:"bold",fontSize:"12px", value: 'Scores', angle: -90, position: 'insideLeft' }}/>
        <Tooltip content={<CustomTooltip />} />
        <Legend formatter={renderColorfulLegendText} wrapperStyle={{fontWeight:"bold", paddingLeft:"5px", fontSize:"12px",lineHeight:"20px"}} layout="vertical" verticalAlign="top" height={25} align={"right"}/>       
        <Bar dataKey="totalScore" name="Total Score" stackId="a" fill="url(#totalActionsBar)" />
        {
          undefined !== props.currentActionPlanningName && null !== props.currentActionPlanningName
          ?
          <Bar dataKey={props.currentActionPlanningName} stackId="a" fill="url(#currentActionBar)" />
          :
          ""
        }
        
        
      </BarChart>
    </ResponsiveContainer>
  )
}

export const BarChartComp = (props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={props.data}
        onClick={props.onClick}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <defs>
          <linearGradient
            id="totalActionsBar"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
            spreadMethod="reflect"
          >
            <stop offset="0" stopColor="#ff9c64" />
            <stop offset="1" stopColor="orange" />
          </linearGradient>        
          <linearGradient
            id="currentActionBar"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
            spreadMethod="reflect"
          >
            <stop offset="0" stopColor="#ff8365" />
            <stop offset="1" stopColor="#ff8365" />
          </linearGradient>        
        </defs>                

        <CartesianGrid strokeDasharray="3 3" />
        <XAxis interval={0} tick={{fontSize:"7px"}} dataKey="axis" label={{ fontWeight:"bold", fontSize:"12px", value: 'List of Health Indicators', angle: 0, position: 'insideBottom', offset:0 }}/>
        <YAxis tick={{fontSize:"10px"}} label={{ fontWeight:"bold",fontSize:"12px", value: 'Scores', angle: -90, position: 'insideLeft' }}/>
        <Tooltip content={<CustomTooltip />} />
        <Legend 
          content={renderBarChartLegendContent} 
          wrapperStyle={{fontWeight:"bold", paddingLeft:"5px", fontSize:"8px",lineHeight:"20px"}} 
          layout="vertical"
          verticalAlign="top" 
          height={25} 
          align={"right"}
        /> 
        <Bar dataKey="totalScore" name="Total Score"  fill="url(#totalActionsBar)" >
          {
            true === props.applyCategoryColor
            ?
            props.data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={CATEGORY_COLOR_MAPPING[entry.axis]} />
            ))  
            :
            ""
          }
        </Bar>



        {
          undefined !== props.currentActionPlanningName && null !== props.currentActionPlanningName
          ?
          <Bar dataKey={props.currentActionPlanningName}  fill="url(#currentActionBar)" />
          :
          ""
        }
        
        
      </BarChart>
    </ResponsiveContainer>
  )
}


export const BarChartSubCategoryComp = (props) => {  
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        barCategoryGap={10}
        data={props.data}
        margin={{
          top: 10,
          right: 30,
          left: 20,
          bottom: 100,
        }}
      >
        <defs>
          <linearGradient
            id="totalActionsBar"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
            spreadMethod="reflect"
          >
            <stop offset="0" stopColor="green" />
            <stop offset="1" stopColor="lightgreen" />
          </linearGradient>        
          <linearGradient
            id="currentActionBar"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
            spreadMethod="reflect"
          >
            <stop offset="0" stopColor="red" />
            <stop offset="1" stopColor="orange" />
          </linearGradient>        
        </defs>                

        <CartesianGrid strokeDasharray="3 3" />
        <XAxis interval={0} scale={"auto"} tick={{zIndex:999,fontSize:"9px", angle:40, textAnchor:"start"}} dataKey="axis" label={{ fontWeight:"bold", fontSize:"12px", value: "", angle: 0, position: 'insideBottom', offset:0 }}/>
        <YAxis tick={{fontSize:"10px"}} label={{ fontWeight:"bold",fontSize:"12px", value: 'Score', angle: -90, position: 'insideLeft' }}/>
        <Tooltip content={<CustomTooltip />} />
        <Legend formatter={renderColorfulLegendText} wrapperStyle={{fontWeight:"bold", paddingLeft:"5px", fontSize:"8px",lineHeight:"20px"}} layout="vertical" verticalAlign="top" height={25} align={"right"}/>       
        <Bar dataKey="totalScore" name="Total Score"  fill={props.subCategoryColor} />
        
        
      </BarChart>
    </ResponsiveContainer>
  )

}

export const DonutChartComp = (props) => {
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  return (
    
    <PieChart width={500} height={500}>
      <Pie
        data={props.data}
        cx={140}
        cy={210}
        innerRadius={100}
        outerRadius={130}
        fill="#8884d8"
        dataKey="fullValue"
        paddingAngle={0}
      >
        {props.data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
 
  )
}