
import styles from "./Card.module.css"
import { useHistory } from "react-router-dom";

export default function Card({ title, logo, action1, titleColor, text, url }) {
    const history = useHistory();
    const urlHandler = () => {
        history.push({ "pathname": url })
    }
    return (
        <div className={styles.wrapper}>
            <div className={styles.logo_wrapper} >  <img alt='tool-logo' height='160' src={logo} /></div>
            <div className={styles.app_title} style={{ color: titleColor }}>{title}</div>
            <div className={styles.card_text}>{text}</div>
            <button onClick={urlHandler} className={styles.link_to_app}>{action1}</button>
        </div>
    )
}