
import { Button, Drawer, Radio, Space, Modal, Input, notification, Form } from 'antd';
import React, { useState, useEffect } from 'react';
import Comment from './Comment';
import {
    FlagOutlined,
    StarOutlined,
    ExclamationOutlined,
    ClockCircleOutlined,
    WechatOutlined,
} from '@ant-design/icons';

import styles from './Comment.module.css'
import API from '../../../../services/API.js';
import { ENDPOINTS } from '../../../../services/endpoints.js';
const openNotification = (type, title, msg) => {
    notification[type]({
        message: title,
        description:
            msg,
    });
};

const { TextArea } = Input;

export default function Comments() {
    const [form] = Form.useForm();
    const [comments, setComments] = useState([])
    const [cityId, setCityId] = useState(0)
    const saveComments = () => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        };
        API.put(`/city/comment/${cityId}`, comments, config)
            .then(res => openNotification('success', 'Action completed', 'Comments updated'))
            .catch(err => {
                openNotification('error', 'Something went wrong', 'An internal error occured. If this error persists, please connect with administrator.')
                console.log(err.response)
            })
    }
    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    const removeItem = (key) => {
        setComments(comments.filter(item => item.id !== key))
    }
    const addItem = (newComment) => {
        setComments([...comments, newComment])
    }
    const [modalVisible, setModalVisible] = useState(false)
    useEffect(() => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        };
        //let domain = 'thess'
        const domain = window.location.hostname.substring(0, window.location.hostname.indexOf("."));
        API.get(`${ENDPOINTS.API_FETCH_CITY_INFO}/${domain}`, config)
            .then(res => {
                if (res.status === 200) {
                    setCityId(res.data.cityId)
                    const txt = res.data.comments ? res.data.comments : '[]'
                    const txt2 = txt.replace(/\\/g, "");
                    const obj = JSON.parse(txt2)
                    setComments(obj)
                }
            })
            .catch(err => console.log(err))
    }, [])

    return (
        <>
            <WechatOutlined className={styles.comments_icon} onClick={showDrawer} />

            <Drawer
                title="Project comments"
                id='drawer'
                width={500}
                onClose={onClose}
                visible={visible}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="primary" onClick={onClose}>
                            OK
                        </Button>
                    </Space>
                }
            >
                {
                    comments.map((item, idx) => <Comment key={idx} properties={item} removeItem={removeItem} />)
                }
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    <Button onClick={() => setModalVisible(true)}>  Add new comment</Button>
                    <Button onClick={saveComments}>  Save</Button>
                </div>
            </Drawer>
            <Modal visible={modalVisible} onCancel={() => { setModalVisible(false); form.resetFields() }} onOk={addItem} footer={null}>
                <Form
                    style={{ padding: '20px 0px' }}
                    name="basic"
                    form={form}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    onFinish={(e) => {
                        addItem({
                            ...e, id: Math.random(),
                            date: new Date().toLocaleString(),
                        });
                        form.resetFields(); setModalVisible(false)
                    }}
                    autoComplete="off"
                >
                    <Form.Item
                        name="auth"
                    >
                        <Input placeholder='Author' />
                    </Form.Item>

                    <Form.Item
                        name="comment"
                    >
                        <TextArea rows={4} placeholder="Comment, maximum 150 characters" maxLength={150} />
                    </Form.Item>
                    <Form.Item
                        label="Icon"
                        name="icon"
                    >
                        <Radio.Group>
                            <Radio value={'star'}><StarOutlined /></Radio>
                            <Radio value={'flag'}>   <FlagOutlined /></Radio>
                            <Radio value={'clock'}> <ClockCircleOutlined /></Radio>
                            <Radio value={'exclamation'}><ExclamationOutlined /></Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item  >
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

