import React from "react";
import { Alert, Spin, Typography } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import spinnerStyles from "./ShowSpinnerIfBusy.module.css"

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Title, Text } = Typography;

export const ShowErrorIfAny = (props) => {
  const error = props.error;
  const onAlertClose = (event) => {

  }

  return (
    <>
      {
        null !== error && error.length > 0
          ?
          <Alert
            message={"Something went wrong!"}
            description={error}
            type="error"
            showIcon
            closable
            onClose={onAlertClose}
            style={{ width: "80%", margin: "auto", padding: "3px 20px 4px 20px" }}
          />
          :
          ""
      }
    </>
  )
}

export const ShowSpinnerIfBusy = (props) => {
  const loading = props.loading;
  const loadingMessage = props.loadingMessage;
  return (
    <>
      {
        loading
          ?
          <div className={`${props.className ? props.className : spinnerStyles.loading}`}>
            <Text>{loadingMessage ? loadingMessage : "Loading...."}</Text>
            <SpinComp />
          </div>
          :
          ""
      }
    </>
  )
}


const SpinComp = (props) => {
  return (
    <Spin indicator={loadingIcon} />
  )
};


export const prepareSpiderChartData = (itemsData, currentActionPlanningData, spiderChartData, spiderChartDataOnScoreUpdate) => {
  if (undefined === itemsData || Object.keys(itemsData).length === 0) {
    return;
  }

  if (undefined === currentActionPlanningData || Object.keys(currentActionPlanningData).length === 0) {
    return;
  }

  if (undefined === spiderChartData || Object.keys(spiderChartData).length === 0) {
    return;
  }

  if (undefined === spiderChartData.averagesPerItem || spiderChartData.averagesPerItem.length === 0) {
    return;
  }

  let spiderChartDataArr = [];
  itemsData.forEach((itemInfo, idx) => {
    const averageItemsArr = spiderChartData.averagesPerItem.filter((averagesPerItem) => averagesPerItem.item.itemId === itemInfo.itemId);
    const averageTotalPoints = averageItemsArr[0].average;

    const averageItemsOnScoreUpdateArr = spiderChartDataOnScoreUpdate.averagesPerItem.filter((averagesPerItem) => averagesPerItem.item.itemId === itemInfo.itemId);
    const averageTotalPointsOnScoreUpdate = averageItemsOnScoreUpdateArr[0].average;

    let currPoints = 0;
    if (undefined !== currentActionPlanningData && Object.keys(currentActionPlanningData).length > 0 && undefined !== currentActionPlanningData.items) {
      const currDataItemsArr = currentActionPlanningData.items.filter((item) => item.item.itemId === itemInfo.itemId);
      currDataItemsArr.forEach((cdi) => {
        currPoints = cdi.score.points;
      })
    }

    if (-1 === currPoints) {
      currPoints = 0;
    }
    spiderChartDataArr.push({
      "axis": idx + 1,
      "title": itemInfo.name.substring(0, 60) + "...",
      "totalActions": averageTotalPointsOnScoreUpdate,
      [currentActionPlanningData.name]: currPoints,
      "fullValue": 10
    })
  })
  return spiderChartDataArr;
}

export const prepareFinalSpiderChartData = (itemsData, spiderChartData) => {
  if (undefined === itemsData || Object.keys(itemsData).length === 0) {
    return;
  }


  if (undefined === spiderChartData || Object.keys(spiderChartData).length === 0) {
    return;
  }

  if (undefined === spiderChartData.averagesPerItem || spiderChartData.averagesPerItem.length === 0) {
    return;
  }

  let spiderChartDataArr = [];
  itemsData.forEach((itemInfo, idx) => {
    const itemsArr = spiderChartData.averagesPerItem.filter((averagesPerItem) => averagesPerItem.item.itemId === itemInfo.itemId);
    const averageTotalPoints = itemsArr[0].average;

    spiderChartDataArr.push({
      "axis": idx + 1,
      "title": itemInfo.name.substring(0, 60) + "...",
      "totalActions": averageTotalPoints,
      "fullValue": 10
    })
  })
  return spiderChartDataArr;
}

export const prepareBarChartData = (currentActionPlanningName, barChartData) => {

  if (undefined === barChartData || Object.keys(barChartData).length === 0) {
    return;
  }

  if (undefined === barChartData.totalScore || Object.keys(barChartData.totalScore).length === 0) {
    return;
  }

  if (undefined === barChartData.totalScoreForPlan || Object.keys(barChartData.totalScoreForPlan).length === 0) {
    return;
  }

  let barChartDataArr = [];

  const keysArr = Object.keys(barChartData.totalScore);
  keysArr.forEach((key) => {
    barChartDataArr.push({
      "axis": key,
      "title": key.substring(0, 60) + "...",
      [currentActionPlanningName]: barChartData.totalScoreForPlan[key],
      "totalScore": barChartData.totalScore[key]
    })
  })


  return barChartDataArr;
}

export const prepareFinalBarChartData = (barChartData) => {

  if (undefined === barChartData || Object.keys(barChartData).length === 0) {
    return;
  }

  if (undefined === barChartData.totalScore || Object.keys(barChartData.totalScore).length === 0) {
    return;
  }

  if (undefined === barChartData.totalScoreForHealthIndicator || barChartData.totalScoreForHealthIndicator.length === 0) {
    return;
  }

  let barChartDataArr = [];
  const keysArr = Object.keys(barChartData.totalScore);
  keysArr.forEach((key) => {
    barChartDataArr.push({
      "axis": key,
      "title": key.substring(0, 60) + "...",
      "totalScore": barChartData.totalScore[key]
    })
  })


  let catAndSubCatHealthIndicatorInfo = {};
  barChartData.totalScoreForHealthIndicator.forEach((catAndSubCat) => {
    if (undefined === catAndSubCatHealthIndicatorInfo[catAndSubCat.category]) {
      catAndSubCatHealthIndicatorInfo[catAndSubCat.category] = [];
    }
    catAndSubCatHealthIndicatorInfo[catAndSubCat.category].push({
      "axis": catAndSubCat.subCategory,
      "title": catAndSubCat.subCategory,
      "category": catAndSubCat.category,
      "totalScore": catAndSubCat.total
    })
  });
  return [barChartDataArr, catAndSubCatHealthIndicatorInfo];
}

export const totalChartData = (itemsData, averagesPerItemArr) => {
  if (undefined === itemsData || Object.keys(itemsData).length === 0) {
    return;
  }

  if (undefined === averagesPerItemArr || averagesPerItemArr.length === 0) {
    return;
  }

  let spiderChartDataArr = [];
  itemsData.forEach((itemInfo) => {
    const itemsArr = averagesPerItemArr.filter((averagesPerItem) => averagesPerItem.item.itemId === itemInfo.itemId);
    const averageTotalPoints = itemsArr[0].average;

    spiderChartDataArr.push({
      "axis": itemInfo.itemId,
      "totalActions": averageTotalPoints
    })
  })
  return spiderChartDataArr;
}


// Density: #9966ff >> 1: #e0b7e6 2: #9966ff
// Mobility and transport: #0099cc >> 1: #004d66 2: #005c7a 3: #c6c9e8 4: #007aa3 5: #008ab8 6: #0099cc
// Mixed use and proximity: #28aeab >> 1: #186867 2: #1c7a78 3: #84d3c4 4: #249d9a 5: #28aeab
// Environment and urban landscape: #339966 >> 1: #b0df83 2: #246b47 3: #297a52 4: #2e8a5c 5: #339966
// Housing and Energy: #99cc00 >> 1: #d3e954 2: #99cc00  
export const ITEM_COLOR_MAPPING = [
  { label: 1, color: "#e0b7e6" },
  { label: 2, color: "#e0b7e6" },
  { label: 3, color: "#c6c9e8" },
  { label: 4, color: "#c6c9e8" },
  { label: 5, color: "#c6c9e8" },
  { label: 6, color: "#c6c9e8" },
  { label: 7, color: "#c6c9e8" },
  { label: 8, color: "#c6c9e8" },
  { label: 9, color: "#84d3c4" },
  { label: 10, color: "#84d3c4" },
  { label: 11, color: "#84d3c4" },
  { label: 12, color: "#84d3c4" },
  { label: 13, color: "#84d3c4" },
  { label: 14, color: "#b0df83" },
  { label: 15, color: "#b0df83" },
  { label: 16, color: "#b0df83" },
  { label: 17, color: "#b0df83" },
  { label: 18, color: "#b0df83" },
  { label: 19, color: "#d3e954" },
  { label: 20, color: "#d3e954" }
];

//Note: dont change the array order...
export const ITEM_COLOR_MAPPING_SPIDER = [
  { label: 6, color: "#c6c9e8" },
  { label: 5, color: "#c6c9e8" },
  { label: 4, color: "#c6c9e8" },
  { label: 3, color: "#c6c9e8" },
  { label: 2, color: "#e0b7e6" },
  { label: 1, color: "#e0b7e6" },
  { label: 20, color: "#d3e954" },
  { label: 19, color: "#d3e954" },
  { label: 18, color: "#b0df83" },
  { label: 17, color: "#b0df83" },
  { label: 16, color: "#b0df83" },
  { label: 15, color: "#b0df83" },
  { label: 14, color: "#b0df83" },
  { label: 13, color: "#84d3c4" },
  { label: 12, color: "#84d3c4" },
  { label: 11, color: "#84d3c4" },
  { label: 10, color: "#84d3c4" },
  { label: 9, color: "#84d3c4" },
  { label: 8, color: "#c6c9e8" },
  { label: 7, color: "#c6c9e8" }
];

export const CATEGORY_COLOR_MAPPING = {
  "ENVIRONMENTAL": "#fd7d00",
  "LIFESTYLE": "#fd7d00",
  "MENTAL": "#fd7d00",
  "PHYSICAL": "#fd7d00",
  "WELLBEING": "#fd7d00"
};
