import { useEffect } from "react";
import Layout from "./components/Layout";
import { BrowserRouter } from "react-router-dom";
import 'antd/dist/antd.css';
import './styles/globals.scss'
import API from './services/API.js';
import { ENDPOINTS } from './services/endpoints.js';
import { useDispatch } from 'react-redux';
import { appActions } from "./redux/reducers/appReducer";

export default function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const payload = {
      token: localStorage.getItem('token')
    }
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    };
    API.post(ENDPOINTS.VALIDATE_TOKEN, payload, config)
      .then(res => {
        if (res.data) {
          dispatch(appActions.userLogin());
        } else {
          dispatch(appActions.userLogout());
        }
      })
      .catch(err => {
        dispatch(appActions.userLogout());
      })
  }, [])
  return (
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  );
}


