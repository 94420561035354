import React, { useState, useEffect } from "react";
import { Button, Typography, Popover } from "antd";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useHistory } from "react-router-dom";
import { SpiderChartComp, BarChartComp, BarChartSubCategoryComp } from "../charts/charts";
import { ITEM_COLOR_MAPPING, CATEGORY_COLOR_MAPPING } from "../../../common/utils";
import styles from "./PlanningActionResult.module.css";
import { Link } from 'react-router-dom';
import logo from '../../../../assets/images/app-logo.png';
const { Title } = Typography;

const PlanningActionResultComp = (props) => {
    const history = useHistory();
    const spiderChartData = undefined !== props.location && undefined !== props.location.state ? props.location.state.spiderChartData : [];
    const scoreItems = undefined !== props.location && undefined !== props.location.state ? props.location.state.scoreItems : [];
    const barChartData = undefined !== props.location && undefined !== props.location.state ? props.location.state.barChartData : [];
    const catAndSubCatHealthIndicatorInfo = undefined !== props.location && undefined !== props.location.state ? props.location.state.catAndSubCatHealthIndicatorInfo : {};
    const overallScoreData = undefined !== props.location && undefined !== props.location.state ? props.location.state.overallScoreData : 0;

    const staticDataLoaded = undefined !== props.location && undefined !== props.location.state ? props.location.state.staticDataLoaded : false;

    const [angleMouseOverValue, setAngleMouseOverValue] = useState(-1);
    const [selectedBarLabel, setSelectedBarLabel] = useState("");


    const planningPageClickHandler = () => {
        history.push({
            "pathname": `/urban-planning-portal`,
            "state": { "staticDataLoaded": staticDataLoaded }
        });
    }
    const renderItems = () => {
        const itemsArr = undefined !== scoreItems
            && scoreItems.length > 0
            && scoreItems
                .filter((scoreItem, idxPos) => idxPos === angleMouseOverValue - 1)
                .map((scoreItem, idx) => {
                    const scoreNumberColor = ITEM_COLOR_MAPPING[(angleMouseOverValue - 1) % Object.keys(ITEM_COLOR_MAPPING).length].color;
                    return (
                        <div key={idx} className={styles.itemIdContainer}>
                            <div className={styles.itemId} style={{ backgroundColor: scoreNumberColor }}>{angleMouseOverValue}</div>
                            <div className={styles.itemTitle}>{scoreItem.title}</div>
                        </div>
                    )
                });
        return itemsArr;
    }

    const angleMouseOverHandler = (value) => {
        setAngleMouseOverValue(value);
    }

    const angleMouseOutHandler = (value) => {
        setAngleMouseOverValue(-1);
    }

    const axisLineMouseOverHandler = (value) => {
        setAngleMouseOverValue(value);
    }
    const axisLineMouseOutHandler = () => {
        setAngleMouseOverValue(-1);
    }

    const barChartClickHandler = (data) => {
        if (undefined !== data && null !== data) {
            setSelectedBarLabel(data.activeLabel);
        }
    }

    const dialogTitle = "Score Info"
    const dialogContent = (
        <div>
            <p style={{ width: "150px" }}>The score considers the overall scope and impact of actions on the urban determinants influencing health. This score is only accurate if you haven’t hidden any urban determinants.</p>
        </div>
    );

    useEffect(() => {
        if (false === staticDataLoaded) {
            history.push({
                "pathname": `/`,
                "state": { "staticDataLoaded": staticDataLoaded }
            });
        }
    }, [staticDataLoaded])

    return (
        <Container fluid style={{ marginTop: -20 }}>
            <Row>
                <Col>
                    <div className={styles.notCompatibleMessage}>
                        Sorry! Mobile devices are not supported in the
                        current version of Healthy Cities Generator.
                        Please use Desktop/Laptop preferably using Google
                        Chrome browser.
                    </div>
                </Col>
            </Row>
            {/* Top right box 9/10 */}
            <Row className={styles.formNotCompatible}>
                <Col md={12}>
                    <div className={styles.overAllScoreContainer}>
                        <div className={styles.overallScoreFlex}>
                            <div className={styles.overallScore}>
                                <Popover content={dialogContent} title={dialogTitle} trigger="click">
                                    <Title className={styles.overallScoreText}>
                                        <span className={styles.numerator}>{overallScoreData}</span>
                                        <span className={styles.divider}>/</span>
                                        <span className={styles.denominator}>10</span>
                                    </Title>
                                </Popover>
                            </div>
                            <div className={styles.overallScoreLogoFlex}>
                                <div >
                                    <Popover content={dialogContent} title={dialogTitle} trigger="click">
                                        <div className={styles.logo_wrapper} >
                                            <img alt='tool-logo' height='20' src={logo} />
                                        </div>

                                    </Popover>
                                </div>
                                <div className={styles.score} >
                                    <Popover content={dialogContent} title={dialogTitle} trigger="click">
                                        <Title className={styles.scoreText} style={{ margin: 0 }}>{"Score"}</Title>
                                    </Popover>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            {/* back to main page & title */}
            <Row className={styles.formNotCompatible}>
                <Col md={12}>

                    <div className={styles.resultHeadingContainer}>
                        <div >
                            <div type="link" className={styles.item} onClick={planningPageClickHandler}>Back To Planning Page</div>
                        </div>
                        <div className={styles.resultHeading}>
                            <Title level={2} className={styles.heading}>Final Results</Title>
                        </div>
                        <Link className={styles.item} to="/pdfs/Health_Impacts_of_Urban_Determinants.pdf" target="_blank" download>Health indicators: find out more</Link>

                    </div>
                </Col>
            </Row>

            <Row className={styles.formNotCompatible}>
                <Col md={6}>
                    <Row>
                        <Col md={12}>
                            <div className={styles.spiderChartContainer} >
                                {
                                    undefined !== spiderChartData && spiderChartData.length > 0
                                        ?
                                        <>
                                            <SpiderChartComp
                                                data={spiderChartData}
                                                innerRadius={"70%"}
                                                outerRadius={"74%"}
                                                onAngleMouseOver={angleMouseOverHandler}
                                                onAngleMouseOut={angleMouseOutHandler}
                                                onAxisLineMouseOver={axisLineMouseOverHandler}
                                                onAxisLineMouseOut={axisLineMouseOutHandler}
                                            />
                                            {
                                                <div className={styles.itemsContainer}>
                                                    {
                                                        -1 === angleMouseOverValue
                                                            ?
                                                            <div className={styles.mouseOverMsg}>Please mouse over on the chart to see the description.</div>
                                                            :
                                                            renderItems()
                                                    }
                                                </div>
                                            }
                                        </>
                                        :
                                        ""
                                }
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <div className={styles.stackedBarChartContainer}>
                        {
                            undefined !== barChartData && barChartData.length > 0
                                ?
                                <>
                                    <BarChartComp
                                        data={barChartData}
                                        onClick={barChartClickHandler}
                                        applyCategoryColor={true}
                                    />

                                    <div className={styles.mouseClickMsg}>Please click each bar to see the distribution.</div>
                                </>
                                :
                                ""
                        }
                    </div>
                </Col>
            </Row>
            {/* bar chart that shows the distribution*/}
            {
                undefined !== catAndSubCatHealthIndicatorInfo
                    && Object.keys(catAndSubCatHealthIndicatorInfo).length > 0
                    && undefined !== catAndSubCatHealthIndicatorInfo[selectedBarLabel]
                    && Object.keys(catAndSubCatHealthIndicatorInfo[selectedBarLabel]).length > 0
                    ?
                    <>
                        <Row>
                            <Col md={12}>
                                <div className={styles.catSubCatBarChartTitle}>
                                    {selectedBarLabel}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className={styles.catSubCatBarChartContainer}>
                                    <BarChartSubCategoryComp
                                        data={catAndSubCatHealthIndicatorInfo[selectedBarLabel]}
                                        subCategoryColor={CATEGORY_COLOR_MAPPING[selectedBarLabel]}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </>
                    :
                    ""
            }

        </Container>

    )
}
export default PlanningActionResultComp;