import { Popconfirm } from 'antd';
import { FlagOutlined, StarOutlined, ExclamationOutlined, ClockCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import styles from './Comment.module.css';

function Icon(icon) {
    switch (icon) {
        case 'star':
            return <StarOutlined style={{ color: 'orange' }} />
        case 'flag':
            return <FlagOutlined style={{ color: 'red' }} />
        case 'clock':
            return <ClockCircleOutlined style={{ color: 'blue' }} />
        case 'exclamation':
            return <ExclamationOutlined style={{ color: 'green' }} />
    }
}

export default function Comment({ properties, removeItem }) {

    const { auth, date, comment, id, icon } = properties

    return (
        <div>
            <div className={styles.comment_header}>
                <div className={styles.header_auth}>
                    <div>{auth}</div>
                    <div>{Icon(icon)}</div>
                    <Popconfirm
                        title="Delete this for sure?"
                        onConfirm={() => removeItem(id)} >
                        <DeleteOutlined />
                    </Popconfirm>
                </div>
            </div>
            <div className={styles.comment_text}>{comment}</div>
            <div className={styles.comment_date}>{date}</div>
        </div >
    )
}