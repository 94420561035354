import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Typography, Dropdown, Menu, Space } from 'antd';
import { EnterOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useStaticData } from "../../../../custom-hooks/appHooks";
import { appActions } from '../../../../redux/reducers/appReducer.js';
import { useDispatch } from "react-redux";
import styles from "./Header.module.css";
import logo from '../../../../assets/images/nav_logo.jpg';
const { Paragraph } = Typography;

export default function HeaderComp(props) {
    const location = useLocation();

    const setWindowRef = (link) => {
        window.location.href = link
    }
    const menu = <div className={styles.mobileMenu}>
        <div className={styles.links} onClick={() => setWindowRef('https://healthycitiesgenerator.com/model/')} >Model </div>
        <div className={styles.links} onClick={() => setWindowRef('https://healthycitiesgenerator.com/resources/')} >Resources </div>
        <div className={styles.links} onClick={() => setWindowRef('https://healthycitiesgenerator.com/meet-the-team/')} >Get in touch </div>
    </div >

    const dispatch = useDispatch();
    const history = useHistory();
    const [cityInfo] = useStaticData();
    const cityId = cityInfo.cityId;
    const [descriptionInfo, setDescriptionInfo] = useState();
    const [descriptionEditable, setDescriptionEditable] = useState(true);
    const [descriptionEnabled, setDescriptionEnabled] = useState(false);
    history.listen((location, action) => {
        if (location.pathname === "/urban-planning-portal" || location.pathname === "/health-planning-portal") {
            setDescriptionEditable(true);
        }
        else {
            setDescriptionEditable(false);
        }
    })

    history.listen((location, action) => {
        if (location.pathname !== "/" && location.pathname !== "/login") {
            setDescriptionEnabled(true);
        }
        else {
            setDescriptionEnabled(false);
        }
    })

    const editableChangeHandler = (text) => {
        if (text.trim().length === 0) {
            text = "No description is available."
        }
        setDescriptionInfo(text);

        if (props.onDescriptionUpdate) {
            props.onDescriptionUpdate(text, cityId);
        }

    }

    useEffect(() => {
        if (window.location.pathname !== "/") {
            setDescriptionEditable(false);
        }
    }, []);

    useEffect(() => {
        if (undefined !== cityInfo.description) {
            setDescriptionInfo(cityInfo.description);
        }
    }, [cityInfo.description]);

    const logout = () => {
        dispatch(appActions.userLogout());
        localStorage.removeItem('token')
        history.push('/')
    }

    return (
        <div className={styles["flex-container"]} style={{ justifyContent: 'space-between' }} >
            <div style={{ display: 'flex' }}>
                <div onClick={() => history.push('/')}>

                    <img alt='hs-logo' className={styles.logo} height='100' src={logo} />
                </div>
            </div>
            {
                descriptionEnabled
                &&
                <div className={styles.description}>
                    {
                        descriptionEditable
                            ?
                            <Paragraph
                                icon={<EnterOutlined style={{ fontSize: '2px!important' }} />}
                                className={styles.descriptionText}
                                editable={{ onChange: editableChangeHandler, tooltip: "Click to edit the description" }}
                            >
                                {descriptionInfo}
                            </Paragraph>
                            :
                            <Paragraph
                                className={styles.descriptionText}
                            >
                                {descriptionInfo}
                            </Paragraph>
                    }
                </div>
            }
            {
                location.pathname !== '/urban-planning-portal/result' ?
                    <React.Fragment>
                        <div className={styles.header_links} >
                            <a className={styles.links} href='https://healthycitiesgenerator.com/model/' target="_blank">Model</a>
                            <a className={styles.links} href='https://healthycitiesgenerator.com/resources/' target="_blank">Resources</a>
                            <a className={styles.links} href='https://healthycitiesgenerator.com/meet-the-team/' target="_blank">Get in touch</a>
                            {!props.isUser && <div className={styles.links} onClick={() => history.push('/login')}>Login</div>}
                            {props.isUser && < div className={styles.links} onClick={logout}>Logout</div>}
                        </div >
                        <div className={styles.mobileBtn} onClick={e => e.preventDefault()}>
                            <Dropdown overlay={menu} trigger={['click']}>
                                <MenuFoldOutlined />
                            </Dropdown>

                        </div>
                    </React.Fragment >
                    :
                    <div style={{ width: 340 }} />
            }
        </div>
    )
}