import styles from "./PlanningAction.module.css";
import { EyeOutlined, EyeInvisibleOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Form, Input, Button, Collapse, Typography, Popconfirm, Empty, Radio, Switch, Tooltip } from 'antd';
import { useState } from 'react';
import { useEffect } from "react";

const { Panel } = Collapse;
const { Title } = Typography;

const ScoreItem = ({ scoreNumberColor, idx, scoreItem, props, radioChangeHandler, isHidden, hiddenItemsHandler }) => {
    useEffect(() => {
        if (isHidden) {
            setActiveKey(null)
            setVisible(false)
            radioChangeHandler(scoreItem, { scoreId: 9, name: 'Neutral', points: 0 })
        } else {
            setVisible(true)
        }
    }, [isHidden])
    const [visible, setVisible] = useState(!isHidden);
    const [activeKey, setActiveKey] = useState(null)
    const { itemsPlanId, item, score } = scoreItem;
    const { itemId, name: scoreItemSectionTitle, title: scoreItemTitle, description: scoreItemText } = item;
    const { scoreId, name: scoreValue, points } = score;


    return (
        <Collapse
            ghost={true}
            activeKey={activeKey}
            key={itemsPlanId}
            onChange={(key) => setActiveKey(key)}
        >
            <Panel
                className={styles.scorePanel}
                showArrow={null}
                collapsible={visible ? 'header' : 'disabled'}
                style={{ backgroundColor: visible ? 'transparent' : 'rgba(0,0,0,0.2)' }}
                extra={
                    <div className={styles.extras_wrapper}>
                        <Tooltip placement="top" title="Hide any urban determinants that aren't relevant for your project. This means they won’t be taken into account in the final score" mouseEnterDelay={0.9}>
                            <div className={styles.extras}>   {visible ? <EyeOutlined onClick={() => hiddenItemsHandler(itemId, !isHidden)} /> : <EyeInvisibleOutlined onClick={() => hiddenItemsHandler(itemId, !isHidden)} />}</div>
                        </Tooltip>

                        <CaretRightOutlined
                            className={styles.extras_btn}
                            onClick={() => visible && setActiveKey((state) => state ? null : itemsPlanId)}
                            style={{
                                transform: activeKey && activeKey?.length !== 0 ? 'rotate(90deg' : 'rotate(0deg)',
                                cursor: visible ? 'pointer' : 'not-allowed'
                            }}
                        />
                    </div>
                }
                header={
                    <Title className={styles.scoreSectionTitle} level={4}>
                        <div className={styles.itemIdContainer}><div className={styles.itemId} style={{ backgroundColor: scoreNumberColor }}>{idx + 1}</div> <div className={styles.itemTitle}>{scoreItemSectionTitle}</div></div>
                    </Title>
                }
                key={itemsPlanId}>
                <>
                    <Title level={4} className={styles.scoreItemTitle}>{scoreItemTitle}</Title>
                    <p className={styles.scoreItemText}>{scoreItemText.replace(/<newline>/g, "\r\n")}</p>
                    <Radio.Group className={styles.radioGroup} onChange={null} value={scoreItem.score.scoreId} disabled={!visible}>
                        <div className={styles.scorePointsContainer}>
                            {
                                props.scores.map((scoreInfo) => {
                                    const clsName = styles[scoreInfo.name.toLowerCase()];
                                    return (
                                        <div key={scoreInfo.scoreId} className={`${styles.scorePoint} ${clsName}`} onClick={(e) => visible ? radioChangeHandler(scoreItem, scoreInfo) : null}>
                                            <Radio value={scoreInfo.scoreId} className={styles.scoreRadio}></Radio>
                                            <div className={styles.scoreLabel}>{scoreInfo.name}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Radio.Group>
                </>
            </Panel>
        </Collapse >
    )

}

export default ScoreItem

{/* <ScoreItem
props={props}
idx={idx}
item={item}
score={score}
scoreNumberColor={scoreNumberColor}
scoreItem={scoreItem}
radioChangeHandler={radioChangeHandler}
/> */}