import React, { Fragment } from "react";
import CustomPaginationComp from "../custom-pagination";
import PlusButtonComp from "../plus-button";
import { Button } from 'antd';
import styles from "./Toolbar.module.css"
import Comments from './CommentSection'
const Toolbar = (props) => {

    const newPlanningActionClickHandler = () => {
        props.onClick("NEW-PLANNING-ACTION")
    }

    const pageNumberClickHandler = (selectedPageNumber) => {
        props.onClick(selectedPageNumber);
    }

    //props.pageSize > -1
    //props.currentPage === props.prevPageNumber

    return (
        <Fragment>
            <div className={styles.toolbar}>
                <CustomPaginationComp newPageInitiated={props.newPageInitiated} newPageAdded={props.newPageAdded} pageDeleted={props.pageDeleted} currentPage={props.currentPage} pageSize={props.pageSize} onClick={pageNumberClickHandler} />
                <div style={{display:'flex'}}>
                    <PlusButtonComp newPageInitiated={props.newPageInitiated} onClick={newPlanningActionClickHandler} />
                    <Comments />
                </div>

            </div>
        </Fragment>
    )
}
export default Toolbar;

