import React, {useState, useReducer, useEffect} from "react";
import {Button, Modal, Form, Input, Typography, Select } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { useFormFieldValidations, isNonEmpty, isValidLength } from "../../../../custom-hooks/appHooks";
import styles from "./CreateScoreItem.module.css";

const scoreItemReducer = (state, action) => {
    switch (action.type) {

        case "FORM_INPUT_UPDATE":
            return {
                ...state,
                [action.fieldName]:action.fieldValue,
                id : uuidv4()
            }

        case "FORM_INPUT_RESET":
            return {
                ...state,
                scoreItemSectionTitle: "",
                scoreItemTitle: "",
                scoreItemText: ""                        
            }
    
        default:
            return state;
    }
}

const { Title } = Typography;
const { Option } = Select;

const CreateScoreItem = (props) => {

    const [form] = Form.useForm();

    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalContent, setModalContent] = useState('Content of the modal');

    const initialState = {
        id:"",
        scoreItemSectionTitle: "",
        scoreItemTitle: "",
        scoreItemText: "",
        scoreValue:""
    };
    const [formState, dispatchFormState] = useReducer(scoreItemReducer, initialState);

    /* Validation */
    const {
        value: scoreItemSectionTitleInfo,
        isValid: scoreItemSectionTitleValid,
        hasError: scoreItemSectionTitleHasError,
        valueChangeHandler: scoreItemSectionTitleChangeHandler,
        valueBlurHandler: scoreItemSectionTitleBlurHandler,
        errorMessage: scoreItemSectionTitleErrorMessage,
        reset: scoreItemSectionTitleResetHandler,    
    } = useFormFieldValidations([
            {"method": isNonEmpty, "message": "Please enter a valid Score Item Section Title"}, 
            {"method": (value) => isValidLength(1, 50, value), "message": "Should not exceed 20 characters"}
    ]);

    const {
        value: scoreItemTitleInfo,
        isValid: scoreItemTitleValid,
        hasError: scoreItemTitleHasError,
        valueChangeHandler: scoreItemTitleChangeHandler,
        valueBlurHandler: scoreItemTitleBlurHandler,
        errorMessage: scoreItemTitleErrorMessage,
        reset: scoreItemTitleResetHandler,    
    } = useFormFieldValidations([
            {"method": isNonEmpty, "message": "Please enter a valid Score Item Title"}, 
            {"method": (value) => isValidLength(1, 50, value), "message": "Should not exceed 20 characters"}
    ]);

    const {
        value: scoreItemTextInfo,
        isValid: scoreItemTextValid,
        hasError: scoreItemTextHasError,
        valueChangeHandler: scoreItemTextChangeHandler,
        valueBlurHandler: scoreItemTextBlurHandler,
        errorMessage: scoreItemTextErrorMessage,
        reset: scoreItemTextResetHandler,    
    } = useFormFieldValidations([
            {"method": isNonEmpty, "message": "Please enter a valid Score Item Text"}, 
            {"method": (value) => isValidLength(1, 360, value), "message": "Should not exceed 150 characters"}
    ]);

    const {
        value: scoreValueInfo,
        isValid: scoreValueValid,
        hasError: scoreValueHasError,
        optionValueChangeHandler: scoreValueChangeHandler,
        valueBlurHandler: scoreValueBlurHandler,
        errorMessage: scoreValueErrorMessage,
        reset: scoreValueResetHandler,    
    } = useFormFieldValidations([
        {"method": isNonEmpty, "message": "Please enter a valid Score Item Text"}
    ]);

    const formIsValid = scoreItemSectionTitleValid && scoreItemTitleValid && scoreItemTextValid && scoreValueValid;

    const hasError = (fieldName) => {
        const errorElement = document.querySelector(`.${fieldName}-error`);
        return undefined !== errorElement && null !== errorElement ? true : false;
    }


    /* End of Validation */

    useEffect(() => {
        setModalContent(
            <>
                <Title level={4}>Create Action Score Item</Title> 
                <Form
                    form={form}
                    layout="vertical"                    
                    onValuesChange={null}                    
                    size={"large"}
                    className={styles.form}
                    onFinish={onFinish}
                    >
                    <Form.Item className={styles.formItemSectionTitle} label="Section Title" name={"scoreItemSectionTitle"} required tooltip="This is a required field">
                        <Input placeholder="Enter Score Item Title" onChange={scoreItemSectionTitleChangeHandler} onBlur={(e)=>inputBlurHandler("scoreItemSectionTitle", e.target.value)}/>
                        {scoreItemSectionTitleHasError && <p className={`${styles.errorMessage} scoreItemSectionTitle-error`}>{scoreItemSectionTitleErrorMessage}</p>}
                    </Form.Item>

                    <Form.Item className={styles.formItemTitle} label="Title" name={"scoreItemTitle"} required tooltip="This is a required field">
                        <Input placeholder="Enter Score Item Heading" onChange={scoreItemTitleChangeHandler}  onBlur={(e)=>inputBlurHandler("scoreItemTitle", e.target.value)}/>
                        {scoreItemTitleHasError && <p className={`${styles.errorMessage} scoreItemTitle-error`}>{scoreItemTitleErrorMessage}</p>}
                    </Form.Item>
                    <Form.Item className={styles.formItemText} label="Text" name={"scoreItemText"} required tooltip="This is a required field">
                        <Input.TextArea placeholder="Enter Score Item Text"  onChange={scoreItemTextChangeHandler}  onBlur={(e)=>inputBlurHandler("scoreItemText", e.target.value)}/>
                        {scoreItemTextHasError && <p className={`${styles.errorMessage} scoreItemText-error`}>{scoreItemTextErrorMessage}</p>}
                    </Form.Item>

                    <Form.Item className={styles.formItemScoreValue} label="Score Value" name={"scoreValue"} required tooltip="This is a required field">
                        <Select style={{ width: 120 }} onChange={scoreValueChangeHandler} onBlur={(value)=>inputBlurHandler("scoreValue", scoreValueInfo)}> 
                            <Option value={1}>Negative</Option>
                            <Option value={2}>Nuetral</Option>
                            <Option value={3}>Low</Option>
                            <Option value={4}>Medium</Option>
                            <Option value={5}>High</Option>
                        </Select>
                        {scoreValueHasError && <p className={`${styles.errorMessage} scoreValue-error`}>{scoreValueErrorMessage}</p>}
                    </Form.Item>

                </Form>

            </>
        );
    }, [scoreItemSectionTitleInfo, scoreItemTitleInfo, scoreItemTextInfo, scoreValueInfo])

    const inputBlurHandler = (fieldName, fieldValue) => {

        if(true === hasError(fieldName)) {
        
            return;
        }

        dispatchFormState({
            type:"FORM_INPUT_UPDATE",
            fieldName,
            fieldValue
        })
    };

    const handleOk = async () => {        
      
        if(true === formIsValid) {
                 
            props.onCreate(formState);    
            setConfirmLoading(true);    
            setVisible(false);
            setConfirmLoading(false);   
            
            scoreItemSectionTitleResetHandler();    
            scoreItemTitleResetHandler();
            scoreItemTextResetHandler();
            scoreValueResetHandler();

            dispatchFormState({
                type:"FORM_INPUT_RESET"
            })
    
            
        }
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const onFinish = (values) => {
    }

    const showModal = (scoreItemSectionTitleInfo) => {
       
        setVisible(true);
    };


    return (
        <div className={styles.scoreItemGenerator}>
            <Button type="primary" shape="round" icon={<PlusOutlined className={styles.addScoreItemIcon}/>} onClick={() => showModal(scoreItemSectionTitleInfo)}>
                Add New Score Item
            </Button>
            <Modal
                title={formState["_id"]}
                visible={visible}
                onOk={handleOk}
                okButtonProps={{"disabled":!formIsValid}}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                destroyOnClose={true}
                
            >
                <div>{modalContent}</div>
            </Modal>                            
        </div>
    )

}
export default CreateScoreItem;

//okButtonProps={{"disabled": null === selectedSrcLang || null === selectedTargetLang}}