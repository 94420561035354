import { combineReducers } from "redux";
import planningActionReducer from "../redux/reducers/planningActionReducer";
import staticDataReducer from "../redux/reducers/staticDataReducer";
import appReducer from "../redux/reducers/appReducer";

const rootReducer = combineReducers({
	planningPages: planningActionReducer,
	staticData: staticDataReducer,
	application: appReducer,

});

export default rootReducer;