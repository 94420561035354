import React, { useState, useReducer, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Collapse, Typography, Popconfirm, Empty, Radio, Switch } from 'antd';
import { DeleteOutlined, SaveOutlined, CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons';
import { useFormFieldValidations, isNonEmpty, isValidLength } from "../../../../custom-hooks/appHooks";
import * as planningActions from "../../../../redux/actions/planningActions";
import { ITEM_COLOR_MAPPING } from "../../../common/utils";
import styles from "./PlanningAction.module.css";
import ScoreItem from './ScoreItem.js';
import axios from 'axios';
import API from '../../../../services/API.js'
import { ENDPOINTS } from '../../../../services/endpoints'
const formLayoutReducer = (state, action) => {
    switch (action.type) {

        case "FORM_INPUT_UPDATE":
            return {
                ...state,
                [action.fieldName]: action.fieldValue
            }

        case "ADD_SCORE_ITEM":
            const scoreItem = action.data;
            return {
                ...state,
                scoreItems: [...state.scoreItems, scoreItem]
            }

        case "DELETE_SCORE_ITEM":
            const scoreItemToBeRemoved = action.data;
            const updatedScoreItems = state.scoreItems.filter((scoreItem) => scoreItem.id !== scoreItemToBeRemoved.id);
            return {
                ...state,
                scoreItems: updatedScoreItems
            }

        case "FORM_SCORE_UPDATE":
            //debugger;

            const itemsPlanId = action.itemsPlanId;
            const scoreInfo = action.scoreInfo;

            const scoreItemIndex = state.scoreItems.findIndex(scoreItem => scoreItem.itemsPlanId === itemsPlanId);
            if (scoreItemIndex != -1) {

                const updatedScoreItem = {
                    ...state.scoreItems[scoreItemIndex],
                    score: { ...scoreInfo },
                    scoreUpdated: true
                };

                //console.log("updatedScoreItem", updatedScoreItem)
                const newScoreItemsArr = [
                    ...state.scoreItems.slice(0, scoreItemIndex),
                    updatedScoreItem,
                    ...state.scoreItems.slice(scoreItemIndex + 1, state.scoreItems.length)
                ]

                return {
                    ...state,
                    scoreItems: newScoreItemsArr
                }
            }

        case "PLANNING_PAGE_CHANGE_UPDATE":
            return {
                ...state,
                actionId: action.actionId,
                actionName: action.actionName,
                actionDescription: action.actionDescrition,
                scoreItems: action.scoreItems
            }


        default:
            return state;
    }
}

const { Panel } = Collapse;
const { Title } = Typography;

const PlanningActionComp = (props) => {
    const cityId = useSelector(state => state.staticData.cityInfo.cityId)
    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState('optional');
    const { planningActionId: actionId, name: actionName, description: actionDescription, items: scoreItems } = props.planningPage;

    const [hiddenItems, setHiddenItems] = useState({});
    const hiddenItemsHandler = (id, state) => {
        setHiddenItems({
            ...hiddenItems,
            [id]: state
        })
    }
    useEffect(() => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        };
        // let domain = 'thess'
        const domain = window.location.hostname.substring(0, window.location.hostname.indexOf("."));
        API.get(`${ENDPOINTS.API_FETCH_CITY_INFO}/${domain}`, config)
            .then(res => {
                console.log(res)
                if (res.status === 200) {
                    const txt = res.data.isActionHidden ? res.data.isActionHidden : '[]'
                    const txt2 = txt.replace(/\\/g, "");
                    const obj = JSON.parse(txt2)
                    let finalObj = {};
                    for (let i = 0; i < obj.length; i++) {
                        Object.assign(finalObj, {
                            [Object.keys(obj[i])]: obj[i][Object.keys(obj[i])] === 'false' || !obj[i][Object.keys(obj[i])] ? false : true
                        });
                    }
                    setHiddenItems(finalObj)
                }
            })
            .catch(err => console.log(err))
    }, [])

    const initialState = {
        actionId,
        actionName,
        actionDescription,
        scoreItems
    };

    const [formState, dispatchFormState] = useReducer(formLayoutReducer, initialState);

    const [scoreItemChanged, setScoreItemChanged] = useState(false);


    /* Validation */
    const {
        value: actionNameInfo,
        isValid: actionNameValid,
        hasError: actionNameHasError,
        valueChangeHandler: actionNameChangeHandler,
        valueBlurHandler: actionNameBlurHandler,
        errorMessage: actionNameErrorMessage,
        reset: actionNameResetHandler,
    } = useFormFieldValidations([
        { "method": isNonEmpty, "message": "Please enter a valid Action Name" },
        { "method": (value) => isValidLength(1, 50, value), "message": "Should not exceed 20 characters" }
    ], actionName);


    const {
        value: actionDescriptionInfo,
        isValid: actionDescriptionValid,
        hasError: actionDescriptionHasError,
        valueChangeHandler: actionDescriptionChangeHandler,
        valueBlurHandler: actionDescriptionBlurHandler,
        errorMessage: actionDescriptionErrorMessage,
        reset: actionDescriptionResetHandler,
    } = useFormFieldValidations([
        { "method": isNonEmpty, "message": "Please enter a valid Action's Description" },
        { "method": (value) => isValidLength(1, 360, value), "message": "Should not exceed 150 characters" }
    ], actionDescription);

    const formIsValid = actionNameValid && actionDescriptionValid && formState.scoreItems.length > 0;


    const hasError = (fieldName) => {
        const errorElement = document.querySelector(`.${fieldName}-error`);
        return undefined !== errorElement && null !== errorElement ? true : false;
    }
    /* End of Validation */

    const inputBlurHandler = (fieldName, fieldValue) => {
        if (true === hasError(fieldName)) {
            console.log("error so skipping the assignment");
            return;
        }

        dispatchFormState({
            type: "FORM_INPUT_UPDATE",
            fieldName,
            fieldValue
        })
    };


    const radioChangeHandler = (scoreItem, scoreInfo) => {
        /*if(true === hasError(fieldName)) {
            console.log("error so skipping the assignment");
            return;
        }*/
        dispatchFormState({
            type: "FORM_SCORE_UPDATE",
            itemsPlanId: scoreItem.itemsPlanId,
            scoreInfo
        });

        setScoreItemChanged(true);

    };

    const onFinish = (values) => {
        //console.log("formState", formState)
    }

    const updatePlanningAction = async (planningActionData) => {
        props.onUpdate(planningActionData);
    };

    const deletePlanningAction = async (planningActionData) => {
        props.onDelete(planningActionData);
    };

    const saveItemsStatus = () => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        };
        const payload = Object.keys(hiddenItems).map(key => { return { [key]: hiddenItems[key] } })
        //console.log(payload)
        API.put(`/city/action/hidden/${cityId}`, payload, config)
    }

    const updatePlanningPageHandler = () => {
        if (true === formIsValid) {
            saveItemsStatus()
            updatePlanningAction({ ...formState });
            //console.log("Planning page updated!")
        }
    }

    const deletePlanningPageHandler = () => {
        if (true === formIsValid) {
            deletePlanningAction({ ...formState });
            //console.log("Planning page deleted!")
        }
    }

    const resetPlanningPageHandler = () => {
        if (true === formIsValid) {
            const neutralScoreInfo = props.scores.filter((scoreInfo) => "Neutral" === scoreInfo.name) || [];
            const neutralDataItems = formState.scoreItems.map((scoreItem) => {
                return ({
                    item: scoreItem.item,
                    itemsPlanId: scoreItem.itemsPlanId,
                    score: { ...neutralScoreInfo[0] },
                    scoreUpdated: true
                })
            })
            //console.log("neutralData", neutralData);
            const neutralData = {
                ...formState,
                scoreItems: neutralDataItems
            }
            updatePlanningAction({ ...neutralData });
        }
    }


    const cancelHandler = () => {

    };
    const generateScoreItems = () => {
        const panels = formState.scoreItems.map((scoreItem, idx) => {
            const { itemsPlanId, item, score } = scoreItem;
            const { itemId, name: scoreItemSectionTitle, title: scoreItemTitle, description: scoreItemText } = item;
            const { scoreId, name: scoreValue, points } = score;
            // const nuetralScoreIdIndex = props.scores.findIndex((score) => score.points === 0.0);
            // const nuetralScoreId = nuetralScoreIdIndex !== -1 ? props.scores[nuetralScoreIdIndex].scoreId : -1;
            const scoreNumberColor = ITEM_COLOR_MAPPING[idx % Object.keys(ITEM_COLOR_MAPPING).length].color
            return (
                <ScoreItem
                    props={props}
                    idx={idx}
                    item={item}
                    score={score}
                    scoreNumberColor={scoreNumberColor}
                    scoreItem={scoreItem}
                    radioChangeHandler={radioChangeHandler}
                    isHidden={hiddenItems[itemId]}
                    hiddenItemsHandler={hiddenItemsHandler}
                />
            )
        });
        return (
            <Collapse

                expandIcon={({ isActive }) => <CaretRightOutlined className={styles.caretRight} rotate={isActive ? 90 : 0} />}
                expandIconPosition="right"
                bordered={false}
                className={styles.scoreItems}
            >
                {panels}
            </Collapse>
        )

    }

    useEffect(() => {
        if (undefined !== props.planningPage && Object.keys(props.planningPage).length > 0) {
            const { planningActionId: actionId, name: actionName, description: actionDescription, items: scoreItems } = props.planningPage;
            dispatchFormState({
                type: "PLANNING_PAGE_CHANGE_UPDATE",
                actionId,
                actionName,
                actionDescription,
                scoreItems
            });

            //document.querySelector("form").classList.add("formFadeIn");
        }
    }, [props.planningPage]);

    useEffect(() => {
        if (true === scoreItemChanged) {
            setScoreItemChanged(false);
            props.onScoreChange({ ...formState })
        }
    }, [scoreItemChanged])

    //console.log("formState", formState)
    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={{ requiredMarkValue: requiredMark }}
                onValuesChange={null}
                requiredMark={requiredMark}
                size={"large"}
                className={`${styles.form}`}
                onFinish={onFinish}
            >
                <Form.Item className={styles.formItemActionName} label="Action Name" name={"actionName"} required tooltip="This is a required field">
                    <Input placeholder="Enter Action Name" defaultValue={formState.actionName} value={actionNameInfo} onChange={actionNameChangeHandler} onBlur={(e) => inputBlurHandler("actionName", e.target.value)} />
                    <Button className={`${styles.saveButton} ${false === formIsValid ? styles.saveButtonDisabled : ""}`} type="secondary" shape="round" icon={<SaveOutlined className={styles.saveIcon} />} onClick={updatePlanningPageHandler}>
                        <span className={styles.saveLabel}>Update</span>
                    </Button>


                    <Popconfirm
                        title={<p className={styles.alertText}>Are you sure to delete this planning page?</p>}
                        onConfirm={deletePlanningPageHandler}
                        onCancel={cancelHandler}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ "shape": "round" }}
                        cancelButtonProps={{ "shape": "round" }}
                    >
                        <Button type="dashed" className={styles.deleteButton} shape="round" icon={<DeleteOutlined className={styles.deleteIcon} />} onClick={null} >
                            <span className={styles.deleteLabel}>Delete</span>
                        </Button>
                    </Popconfirm>

                    <Button className={`${styles.resetButton} ${false === formIsValid ? styles.resetButtonDisabled : ""}`} type="secondary" shape="round" icon={<SaveOutlined className={styles.resetIcon} />} onClick={resetPlanningPageHandler}>
                        <span className={styles.resetLabel}>Reset</span>
                    </Button>

                    {actionNameHasError && <p className={`${styles.errorMessage} actionName-error`}>{actionNameErrorMessage}</p>}
                </Form.Item>
                <Form.Item className={styles.formItemActionDescription} label="Action's Description" name={"actionDescription"} required tooltip="This is a required field">
                    <Input.TextArea style={{ resize: "none" }} placeholder="Enter Action's Description" defaultValue={formState.actionDescription} value={actionDescriptionInfo} onChange={actionDescriptionChangeHandler} onBlur={(e) => inputBlurHandler("actionDescription", e.target.value)} />
                    {actionDescriptionHasError && <p className={`${styles.errorMessage} actionDescription-error`}>{actionDescriptionErrorMessage}</p>}
                </Form.Item>
                {/* Collapsibles with radio checkboxes for spiderchart inputs */}
                {
                    undefined !== formState.scoreItems && formState.scoreItems.length > 0
                        ?
                        generateScoreItems()
                        :
                        <Empty
                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                            imageStyle={{
                                height: 60,
                            }}
                            description={
                                <span className={styles.noScoreItemsAvailable}>
                                    No Score Items available!
                                </span>
                            }
                        >
                        </Empty>
                }
            </Form>
        </>

    )
}
export default PlanningActionComp;



