import styles from './OutputComponent.module.css'
import ResultItem from '../resultItem/ResultItem.js';
import { Checkbox, Button } from 'antd';
import { useState } from 'react';
import { Link } from "react-router-dom";

const OutputComponent = ({ data }) => {
    const [enable, setEnable] = useState(true)
    const onChange = () => {
        setEnable(state => !state)
    }

    return (
        <div>
            <div  style={{ marginBottom: 10 }}>

                <Link to="/pdfs/Urban_Determinants_of_Health.pdf.pdf" className={styles.item}  target="_blank" download>Urban determinants: find out more</Link>
            </div>
            <div className={styles.inputComponent}>
                <div style={{ fontSize: 20, fontWeight: 500, textAlign: 'center' }}>Linked Urban Determinants of Health</div>
                <div style={{ margin: '10px 0px', fontWeight: 500, display: 'flex', justifyContent: 'space-between' }}>
                    <Checkbox checked={enable} onChange={onChange}><span style={{ fontSize: 18, fontWeight: 500 }}>View percentages %</span></Checkbox>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    {
                        data?.map((item, idx) => <ResultItem showLabels={enable} key={idx} label={item.title} color={item.color} score={item.score} />)
                    }
                </div>
            </div>
        </div>
    )
}

export default OutputComponent;