import axios from "axios";


/* Iteration 1 */

const remoteApiUrl = "https://dev-api.healthycitiesapp.com/";

/* Iteration 2 */
//const remoteApiUrl = "https://hcg-api.healthycitiesapp.com/";

const axiosInstance = axios.create({
	baseURL: remoteApiUrl
});


axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

export default axiosInstance;



// /const txt = "[{\"id\": 122, \"auth\": \"Steven Kings\", \"date\": \"17/07/2022, 13:59:18\", \"icon\": \"star\", \"comment\": \"All welcome to the Healthy Cities project for the city of Rome. If you have any questions you can email me at steven_t119@gmail.com.\"}, {\"id\": 123, \"auth\": \"Rachel Green\", \"date\": \"16/07/2022, 14:10:18\", \"icon\": \"star\", \"comment\": \"Hi Andrew, thanks for this. Will start working on it ASAP!!!\"}]"
