import {useState, useEffect, useReducer} from "react";
import {useSelector} from "react-redux";

export const usePlanningPage = (city, selectedPageNumber) => {
    const [selectedPlanningPage, setSelectedPlanningPage] = useState({});
    const planningPageArr = useSelector(state => state.planningPages.cities[city]);
    const totalPlanningPages = planningPageArr ? planningPageArr.length : 0;

    useEffect(() => {
        if(undefined !== planningPageArr && undefined !== planningPageArr[selectedPageNumber-1]) {
          setSelectedPlanningPage(planningPageArr[selectedPageNumber-1]);
        }       
        else {
          setSelectedPlanningPage({})
        }
	  },[planningPageArr, selectedPageNumber]);

    return [totalPlanningPages, selectedPlanningPage, planningPageArr];
}

export const useAveragesPerItem = (city) => {
  const [averagesPerItemArr, setAveragesPerItemArr] = useState({});
  const averagesPerItemData = useSelector(state => state.planningPages.averagesPerItemByCity[city]);

  useEffect(() => {
      if(undefined !== averagesPerItemData) {
        setAveragesPerItemArr(averagesPerItemData);
      }       
  },[averagesPerItemData]);

  return [averagesPerItemArr];
}

export const useChartData = (planningActionId, city) => {
  const key = `${city}_${planningActionId}`;
  const [chartData, setChartData] = useState({});
  const chartDataInfo = useSelector(state => state.planningPages.chartData[key]);

  useEffect(() => {
      if(undefined !== chartDataInfo) {
        setChartData(chartDataInfo);
      }       
  },[chartDataInfo]);

  return [chartData];
}

export const useChartDataOnScoreUpdate = (planningActionId, city) => {
  const key = `${city}_${planningActionId}`;
  const [chartData, setChartData] = useState({});
  const chartDataInfo = useSelector(state => state.planningPages.chartDataOnScoreUpdate[key]);

  useEffect(() => {
      if(undefined !== chartDataInfo) {
        setChartData(chartDataInfo);
      }       
  },[chartDataInfo]);

  return [chartData];
}


export const useStaticData = () => {
  const [cityInfo, setCityInfo] = useState({});
  const [scores, setScores] = useState({});
  const [scoreItems, setScoreItems] = useState({});
  const staticDataInfo = useSelector(state => state.staticData);
  
  useEffect(() => {
      if(undefined !== staticDataInfo && undefined !== staticDataInfo.cityInfo && undefined !== staticDataInfo.scores && undefined !== staticDataInfo.scoreItems) {
        setCityInfo(staticDataInfo.cityInfo);
        setScores(staticDataInfo.scores);
        setScoreItems(staticDataInfo.scoreItems);
      }       
  },[staticDataInfo]);
  return [cityInfo, scores, scoreItems];
}


/** Form Validation Hook **/

const fieldStateReducer = (state, action) => {
  if (action.type === 'INPUT') {
    return { value: action.value, isTouched: true };
  }
  if (action.type === 'BLUR') {
    return { isTouched: true, value: state.value };
  }
  if (action.type === 'RESET') {
    return { isTouched: false, value: '' };
  }
  return fieldStateReducer;
};

export const useFormFieldValidations = (validationArr, initialValue) => {
  const initialFieldState = {
    value: undefined !== initialValue ? initialValue : "",
    isTouched: false,
  };
  
  const [fieldState, dispatchFieldState] = useReducer(
    fieldStateReducer,
    initialFieldState
  );

  const resultArr = validationArr.map((validate) => {
      return validate.method(fieldState.value+"");
  })  

  
  const errorMessages = resultArr.map((result, idx) => {
    return (
      false === result ? validationArr[idx].message : ""
    )
  })

  
  const valueIsValid = ((validationArr.length === 0) || (resultArr.length > 0 && resultArr.indexOf(false) === -1));   //validateValue(fieldState.value);
  const hasError = !valueIsValid && fieldState.isTouched;

  const valueChangeHandler = (event) => {
    dispatchFieldState({ type: 'INPUT', value: event.target.value });
  };

  const optionValueChangeHandler = (selectedOptionValue) => {
    dispatchFieldState({ type: 'INPUT', value: selectedOptionValue });
  };

  const valueBlurHandler = (event) => {
    dispatchFieldState({ type: 'BLUR' });
  };

  const reset = () => {
    dispatchFieldState({ type: 'RESET' });
  };

  useEffect(() => {
    if(undefined !== initialValue) {
      dispatchFieldState({ type: 'INPUT', value:initialValue });
    }       
  },[initialValue]);


  return {
    value: fieldState.value,
    isValid: valueIsValid,
    hasError,
    valueChangeHandler,
    valueBlurHandler,
    optionValueChangeHandler,
    reset,
    errorMessage: errorMessages.find(msg => msg.trim().length > 0)
  };
};
export const isNonEmpty = (value) => value.trim().length > 0;
export const isValidLength = (min, max, value) => value.trim().length >= min && value.trim().length <= max;
/** End of Form Validation Hook **/