
import { ENDPOINTS } from "../../services/endpoints";
import API from "../../services/API";

import {
	PLANNING_ACTIONS_FETCHED,
	PLANNING_ACTION_UPDATED,
	PLANNING_ACTION_ADDED,
	PLANNING_ACTION_DELETED,
	CHART_DATA_FETCHED,
	CHART_DATA_ON_SCORE_UPDATE_FETCHED
}
	from "./types";


export const fetchPlanningActionsByCity = (city) => async dispatch => {
	const ERROR_MESSAGE = "Something went wrong...";

	const url = `${ENDPOINTS.API_FETCH_PLANNING_ACTIONS}/${city}`;

	try {
		const config = {
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
		};
		const response = await API.get(url, config);
		if (response && response.status === 200 && response.data) {
			dispatch({
				type: PLANNING_ACTIONS_FETCHED,
				data: response.data,
				city: city
			});
		}
		else {
			throw new Error(`Fetch Planning pages failed for the City:${city}`);
		}
	}
	catch (error) {
		if (undefined !== error.response) {
			throw new Error(error.response.data.ERROR);
		}
		throw error;
	}
}

export const fetchChartData = (planningActionId, city) => async dispatch => {
	const ERROR_MESSAGE = "Something went wrong...";

	const url = `${ENDPOINTS.API_FETCH_CHART_DATA}/${city}/action/${planningActionId}`;

	try {
		const config = {
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
		};
		const response = await API.get(url, config);
		if (response && response.status === 200 && response.data) {
			dispatch({
				type: CHART_DATA_FETCHED,
				data: {
					"spiderChartData": response.data.spiderChart,
					"barChartData": response.data.columnData,
					"overallScore": response.data.totalScore.toFixed(1)
				},
				planningActionId: planningActionId,
				city: city
			});
		}
		else {
			throw new Error(`Fetch Chart data failed for the City:${city} with the planning action:${planningActionId}`);
		}
	}
	catch (error) {
		if (undefined !== error.response) {
			throw new Error(error.response.data.ERROR);
		}
		throw error;
	}
}

export const fetchChartDataOnScoreItemUpdate = (planningActionData, planningPageArr, city) => async dispatch => {
	const ERROR_MESSAGE = "Something went wrong...";
	let updatedPlanningPageArr = [];
	const planningActionId = planningActionData.planningActionId;

	const url = `${ENDPOINTS.API_FETCH_CHART_DATA}/${city}/action/${planningActionId}`;
	try {

		const planningActionIndex = planningPageArr.findIndex((planningAction => planningAction.planningActionId === planningActionId));
		if (planningActionIndex != -1) {
			updatedPlanningPageArr = [
				...planningPageArr.slice(0, planningActionIndex),
				planningActionData,
				...planningPageArr.slice(planningActionIndex + 1, planningPageArr.length)
			]
		}

		const params = updatedPlanningPageArr;


		const config = {
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
		};
		const response = await API.put(url, params, config);
		if (response && response.status === 200 && response.data) {
			dispatch({
				type: CHART_DATA_ON_SCORE_UPDATE_FETCHED,
				data: {
					"spiderChartData": response.data.spiderChart,
					"barChartData": response.data.columnData,
					"overallScore": response.data.totalScore.toFixed(1)
				},
				planningActionId: planningActionId,
				city: city
			});
		}
		else {
			throw new Error(`Fetch Chart data on Score Item Update got failed for the City:${city} with the planning action:${planningActionId}`);
		}
	}
	catch (error) {
		if (undefined !== error.response) {
			throw new Error(error.response.data.ERROR);
		}
		throw error;
	}
}

export const updatePlanningAction = (planningActionData, city) => async dispatch => {
	const ERROR_MESSAGE = "Something went wrong...";

	const url = `${ENDPOINTS.API_UPDATE_PLANNING_ACTIONS}`;
	//debugger;
	try {

		const updatedScoreItems = planningActionData.scoreItems.filter((scoreItem) => true === scoreItem.scoreUpdated) || [];

		let updatedPlanningActionData = {
			"planningActionId": planningActionData.actionId,
			"name": planningActionData.actionName,
			"description": planningActionData.actionDescription,
		};

		if (updatedScoreItems.length > 0) {
			const itemsData = updatedScoreItems.map((updatedScoreItem) => {
				return (
					{
						"itemsPlanId": updatedScoreItem.itemsPlanId,
						"itemId": updatedScoreItem.item.itemId,
						"scoreId": updatedScoreItem.score.scoreId
					}
				)
			});

			updatedPlanningActionData["items"] = [...itemsData];
		}

		const params = updatedPlanningActionData;
		const config = {
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
		};
		const response = await API.put(url, params, config);
		if (response && response.status === 200) {
			dispatch({
				type: PLANNING_ACTION_UPDATED,
				data: planningActionData,
				city: city
			});
		}
		else {
			throw new Error(`Updates on Planning pages failed for the City:${city}`);
		}
	}
	catch (error) {
		if (undefined !== error.response) {
			throw new Error(error.response.data.ERROR);
		}
		throw error;
	}
}

export const addPlanningAction = (planningActionData, city) => async dispatch => {
	const ERROR_MESSAGE = "Something went wrong...";

	const url = `${ENDPOINTS.API_ADD_PLANNING_ACTION}`;

	try {
		let updatedPlanningActionData = {
			"name": planningActionData.actionName,
			"description": planningActionData.actionDescription,
		};
		const updatedScoreItems = planningActionData.scoreItems.filter((scoreItem) => true === Object.keys(scoreItem.score).length > 0) || [];
		if (updatedScoreItems.length > 0) {
			const itemsData = updatedScoreItems.map((updatedScoreItem) => {
				return (
					{
						"itemId": updatedScoreItem.item.itemId,
						"scoreId": updatedScoreItem.score.scoreId
					}
				)
			});
			updatedPlanningActionData["items"] = [...itemsData];
		}
		updatedPlanningActionData["city"] = { "cityId": city };

		const params = updatedPlanningActionData;
		const config = {
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
		};
		const response = await API.post(url, params, config);
		if (response && response.status === 204) {
			/*dispatch({
				type: PLANNING_ACTION_ADDED,
				data: planningActionData,
				city:city
			});*/
		}
		else {
			throw new Error(`Updates on Planning pages failed for the City:${city}`);
		}
	}
	catch (error) {
		if (undefined !== error.response) {
			throw new Error(error.response.data.ERROR);
		}
		throw error;
	}
}

export const deletePlanningAction = (planningActionData, city) => async dispatch => {
	const ERROR_MESSAGE = "Something went wrong...";
	const planningActionId = planningActionData.actionId;
	const url = `${ENDPOINTS.API_UPDATE_PLANNING_ACTIONS}/${planningActionId}`;
	try {
		const config = {
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
		};
		const response = await API.delete(url, config);
		if (response && response.status === 204) {
			/*dispatch({
				type: PLANNING_ACTION_DELETED,
				data: planningActionData,
				city:city
			});*/
		}
		else {
			throw new Error(`Delete Planning pages failed for the City:${city}`);
		}
	}
	catch (error) {
		if (undefined !== error.response) {
			throw new Error(error.response.data.ERROR);
		}
		throw error;
	}
}
