import styles from './CustomCheckbox.module.css'
import { Checkbox } from 'antd';
import { useEffect } from 'react';

export default function CustomCheckbox({ label, color, index, classType, id, checkboxes, setCheckboxes }) {
    const element = document.getElementsByClassName(`customCheckbox-${color}`.replace('#', ''))
    useEffect(() => {
        if (checkboxes[id]) {
            element[index]?.classList?.add(`${`customCheckbox-${color}`.replace('#', '')}-checked`)
        } else {
            element[index]?.classList?.remove(`${`customCheckbox-${color}`.replace('#', '')}-checked`)
        }
    }, [checkboxes[id]])

    return (
        <div className={styles.CustomCheckbox} >
            <div className={styles.CustomCheckbox_label} style={{ border: `1px solid ${color}` }}>{label}</div>
            <div className={`customCheckbox-${color}`.replace('#', '')}>
                <Checkbox motion={null} checked={checkboxes[id]} onClick={(e) => setCheckboxes({ ...checkboxes, [id]: e.target.checked })} className='antd-checkbox' /></div>
        </div >
    )
}