import React, { useState, useReducer, useEffect } from "react";
import { Form, Input, Button, Collapse, Typography, Popconfirm, Empty, Radio } from 'antd';
import { DeleteOutlined, SaveOutlined, CaretRightOutlined } from '@ant-design/icons';
import CreateScoreItem from "../create-score-item";
import { useFormFieldValidations, isNonEmpty, isValidLength } from "../../../../custom-hooks/appHooks";
import { ITEM_COLOR_MAPPING } from "../../../common/utils";
import styles from "./CreatePlanningAction.module.css";
import { v4 as uuidv4 } from 'uuid';

const formLayoutReducer = (state, action) => {
    switch (action.type) {

        case "FORM_INPUT_UPDATE":
            return {
                ...state,
                [action.fieldName]: action.fieldValue
            }

        case "ADD_SCORE_ITEM":
            const scoreItem = action.data;
            return {
                ...state,
                scoreItems: [...state.scoreItems, scoreItem]
            }

        case "DELETE_SCORE_ITEM":
            const scoreItemToBeRemoved = action.data;
            const updatedScoreItems = state.scoreItems.filter((scoreItem) => scoreItem.id !== scoreItemToBeRemoved.id);
            return {
                ...state,
                scoreItems: [...updatedScoreItems]
            }

        case "FORM_SCORE_UPDATE":
            const itemsPlanId = action.itemsPlanId;
            const scoreInfo = action.scoreInfo;

            const scoreItemIndex = state.scoreItems.findIndex(scoreItem => scoreItem.itemsPlanId === itemsPlanId);
            if (scoreItemIndex != -1) {

                const updatedScoreItem = {
                    ...state.scoreItems[scoreItemIndex],
                    score: { ...scoreInfo }
                };

                //console.log("updatedScoreItem", updatedScoreItem)
                const newScoreItemsArr = [
                    ...state.scoreItems.slice(0, scoreItemIndex),
                    updatedScoreItem,
                    ...state.scoreItems.slice(scoreItemIndex + 1, state.scoreItems.length)
                ]

                return {
                    ...state,
                    scoreItems: newScoreItemsArr
                }
            }



        default:
            return state;
    }
}

const { Panel } = Collapse;
const { Title } = Typography;

const CreatePlanningActionComp = (props) => {
    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState('optional');

    const nuetralScoreIdIndex = props.scores.findIndex((score) => score.points === 0.0);
    const nuetralScore = nuetralScoreIdIndex !== -1 ? props.scores[nuetralScoreIdIndex] : {};

    const initialState = {
        actionId: "",
        actionName: "",
        actionDescription: "",
        scoreItems: props.items.map((item) => {
            return (
                { "itemsPlanId": uuidv4(), "item": { ...item }, "score": { ...nuetralScore } }
            )
        })
    };

    const [formState, dispatchFormState] = useReducer(formLayoutReducer, initialState);


    /* Validation */
    const {
        value: actionNameInfo,
        isValid: actionNameValid,
        hasError: actionNameHasError,
        valueChangeHandler: actionNameChangeHandler,
        valueBlurHandler: actionNameBlurHandler,
        errorMessage: actionNameErrorMessage,
        reset: actionNameResetHandler,
    } = useFormFieldValidations([
        { "method": isNonEmpty, "message": "Please enter a valid Action Name" },
        { "method": (value) => isValidLength(1, 50, value), "message": "Should not exceed 20 characters" }
    ]);

    const {
        value: actionDescriptionInfo,
        isValid: actionDescriptionValid,
        hasError: actionDescriptionHasError,
        valueChangeHandler: actionDescriptionChangeHandler,
        valueBlurHandler: actionDescriptionBlurHandler,
        errorMessage: actionDescriptionErrorMessage,
        reset: actionDescriptionResetHandler,
    } = useFormFieldValidations([
        { "method": isNonEmpty, "message": "Please enter a valid Action's Description" },
        { "method": (value) => isValidLength(1, 360, value), "message": "Should not exceed 150 characters" }
    ]);

    const formIsValid = actionNameValid && actionDescriptionValid && formState.scoreItems.length > 0;

    const hasError = (fieldName) => {
        const errorElement = document.querySelector(`.${fieldName}-error`);
        return undefined !== errorElement && null !== errorElement ? true : false;
    }
    /* End of Validation */

    const inputBlurHandler = (fieldName, fieldValue) => {
        if (true === hasError(fieldName)) {
            console.log("error so skipping the assignment");
            return;
        }
        dispatchFormState({
            type: "FORM_INPUT_UPDATE",
            fieldName,
            fieldValue
        })
    };


    const radioChangeHandler = (scoreItem, scoreInfo) => {
        /*if(true === hasError(fieldName)) {
            console.log("error so skipping the assignment");
            return;
        }*/
        dispatchFormState({
            type: "FORM_SCORE_UPDATE",
            itemsPlanId: scoreItem.itemsPlanId,
            scoreInfo
        })
    };


    const onFinish = (values) => {
    }

    const savePlanningPageHandler = () => {
        if (true === formIsValid) {
            //console.log("Planning Page Saved!")
            formState["actionId"] = uuidv4()
            props.onCreate(formState);

            actionNameResetHandler();
            actionDescriptionResetHandler();
        }
    }

    const cancelPlanningPageHandler = () => {
        // console.log("Planning Page Cancelled!")
        props.onCancel();
    }

    const addScoreItem = (scoreItem) => {
        dispatchFormState({
            type: "ADD_SCORE_ITEM",
            data: scoreItem
        })
    };

    const deleteScoreItemHandler = (scoreItem) => {
        dispatchFormState({
            type: "DELETE_SCORE_ITEM",
            data: scoreItem
        })
    }

    const confirmHandler = (scoreItem) => {
        deleteScoreItemHandler(scoreItem)
    };

    const cancelHandler = () => {

    };

    const COLORS = [
        "#e6194B", "#000075", "#469990", "#808000", "#9A6324", "#800000",
        "#dcbeff", "#F2FB38", "#FF2400", "#7CFC00", "#fabed4",
        "#a9a9a9", "#f032e6", "#911eb4", "#4363d8", "#42d4f4",
        "#3cb44b", "#bfef45", "#ffe119", "#f58231"
    ];

    const generateScoreItems = () => {
        //console.log("props.items", props.items)
        const panels = formState.scoreItems.map((scoreItem, idx) => {
            const { itemsPlanId, item, score } = scoreItem;
            const { itemId, name: scoreItemSectionTitle, title: scoreItemTitle, description: scoreItemText } = item;
            const { scoreId, name: scoreValue, points } = score;

            const nuetralScoreIdIndex = props.scores.findIndex((score) => score.points === 0.0);
            const nuetralScoreId = nuetralScoreIdIndex !== -1 ? props.scores[nuetralScoreIdIndex].scoreId : -1;
            const val = undefined !== scoreItem.score.scoreId ? scoreItem.score.scoreId : nuetralScoreId;
            const scoreNumberColor = ITEM_COLOR_MAPPING[idx % Object.keys(ITEM_COLOR_MAPPING).length].color
            return (
                <Panel
                    className={styles.scorePanel}
                    header={
                        <Title className={styles.scoreSectionTitle} level={4}>
                            <div className={styles.itemIdContainer}><div className={styles.itemId} style={{ backgroundColor: scoreNumberColor }}>{idx + 1}</div> <div className={styles.itemTitle}>{scoreItemSectionTitle}</div></div>
                        </Title>
                    }
                    key={itemsPlanId}>
                    <Title level={4} className={styles.scoreItemTitle}>{scoreItemTitle}</Title>
                    <p className={styles.scoreItemText}>{scoreItemText.replace(/<newline>/g, "\r\n")}</p>
                    <Radio.Group className={styles.radioGroup} onChange={null} value={val}>
                        <div className={styles.scorePointsContainer}>
                            {
                                props.scores.map((scoreInfo) => {
                                    const clsName = styles[scoreInfo.name.toLowerCase()];
                                    return (
                                        <div key={scoreInfo.scoreId} className={`${styles.scorePoint} ${clsName}`} onClick={(e) => radioChangeHandler(scoreItem, scoreInfo)}>
                                            <Radio value={scoreInfo.scoreId} className={styles.scoreRadio}></Radio>
                                            <div className={styles.scoreLabel}>{scoreInfo.name}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Radio.Group>
                </Panel>
            )
        });
        return (
            <Collapse
                expandIcon={({ isActive }) => <CaretRightOutlined className={styles.caretRight} rotate={isActive ? 90 : 0} />}
                expandIconPosition="right"
                bordered={false}
                className={styles.scoreItems}
            >
                {panels}

            </Collapse>
        )

    }

    //console.log("formState", formState);
    return (
        <>
            <Form
                form={form}
                layout="vertical"
                initialValues={{ requiredMarkValue: requiredMark }}
                onValuesChange={null}
                requiredMark={requiredMark}
                size={"large"}
                className={styles.form}
                onFinish={onFinish}
            >
                <Form.Item className={styles.formItemActionName} label="Action Name" name={"actionName"} required tooltip="This is a required field">
                    <Input placeholder="Enter Action Name" onChange={actionNameChangeHandler} onBlur={(e) => inputBlurHandler("actionName", e.target.value)} />
                    <Button className={`${styles.saveButton} ${false === formIsValid ? styles.saveButtonDisabled : ""}`} type="secondary" shape="round" icon={<SaveOutlined className={styles.saveIcon} />} onClick={savePlanningPageHandler}>
                        <span className={`${styles.saveLabel}`}>Save</span>
                    </Button>
                    <Popconfirm
                        title={<p className={styles.alertText}>Are you sure to cancel this planning page?</p>}
                        onConfirm={cancelPlanningPageHandler}
                        onCancel={cancelHandler}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ "shape": "round" }}
                        cancelButtonProps={{ "shape": "round" }}
                    >
                        <Button type="dashed" className={styles.cancelButton} shape="round" icon={<DeleteOutlined className={styles.cancelIcon} />} onClick={null}>
                            <span className={styles.cancelLabel}>Cancel</span>
                        </Button>
                    </Popconfirm>

                    {/*<Button type="dashed" className={styles.deleteButton}  shape="round" icon={<DeleteOutlined className={styles.deleteIcon}/>} onClick={cancelPlanningPageHandler}>
                    <span className={styles.deleteLabel}>Cancel</span>
                </Button>*/}
                    {actionNameHasError && <p className={`${styles.errorMessage} actionName-error`}>{actionNameErrorMessage}</p>}
                </Form.Item>


                <Form.Item className={styles.formItemActionDescription} label="Action's Description" name={"actionDescription"} required tooltip="This is a required field">
                    <Input.TextArea style={{ resize: "none" }} placeholder="Enter Action's Description" onChange={actionDescriptionChangeHandler} onBlur={(e) => inputBlurHandler("actionDescription", e.target.value)} />
                    {actionDescriptionHasError && <p className={`${styles.errorMessage} actionDescription-error`}>{actionDescriptionErrorMessage}</p>}
                </Form.Item>
                {
                    generateScoreItems()
                }
            </Form>
            {/*<CreateScoreItem onCreate={addScoreItem}/>*/}
        </>

    )
}
export default CreatePlanningActionComp;

