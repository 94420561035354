export const PLANNING_ACTIONS_FETCHED = "PLANNING_ACTIONS_FETCHED";
export const PLANNING_ACTION_UPDATED = "PLANNING_ACTION_UPDATED";
export const PLANNING_ACTION_ADDED = "PLANNING_ACTION_ADDED";
export const PLANNING_ACTION_DELETED = "PLANNING_ACTION_DELETED";
export const CITY_INFO_FETCHED = "CITY_INFO_FETCHED";
export const SCORES_FETCHED = "SCORES_FETCHED";
export const SCORE_ITEMS_FETCHED = "SCORE_ITEMS_FETCHED";
export const CHART_DATA_FETCHED = "CHART_DATA_FETCHED";
export const CHART_DATA_ON_SCORE_UPDATE_FETCHED = "CHART_DATA_ON_SCORE_UPDATE_FETCHED";


