import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { Typography } from 'antd';
import Toolbar from "../toolbar";
import PlanningActionComp from "../planning-action";
import CreatePlanningActionComp from "../planning-action-new";
import { ShowSpinnerIfBusy } from "../../../common/utils";
import { PlusOutlined } from "@ant-design/icons"
import { useDispatch } from "react-redux";
import { usePlanningPage, useChartData, useChartDataOnScoreUpdate, useAveragesPerItem, useStaticData } from "../../../../custom-hooks/appHooks";
import * as planningActions from "../../../../redux/actions/planningActions";
import { ITEM_COLOR_MAPPING } from "../../../common/utils";
import { prepareSpiderChartData, prepareBarChartData, prepareFinalSpiderChartData, prepareFinalBarChartData } from "../../../common/utils";
import { SpiderChartComp, BarChartComp } from "../charts/charts";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./Planning.module.css"
import QuickScan from "../quickscan/index.js";
import { Link } from 'react-router-dom'
const { Title, Text } = Typography;

const PlanningComp = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();

    const staticDataLoaded = props.staticDataLoaded;
    const [cityInfo, scores, scoreItems] = useStaticData();
    const [cityData, setCityData] = useState({});
    const [scoresData, setScoresData] = useState([]);
    const [scoreItemsData, setScoreItemsData] = useState([]);


    const domainName = params.domainName;

    const prevPageNumber = useRef(0);
    const [planningPage, setPlanningPage] = useState({});
    const [totalPlanningPageCount, setTotalPlanningPageCount] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const [chartLoading, setChartLoading] = useState(false);

    const [error, setError] = useState("");
    const [loadingMessage, setLoadingMessage] = useState("Loading...");


    const [newPlanningActionInitiated, setNewPlanningActionInitiated] = useState(false);
    const [selectedPageNumber, setSelectedPageNumber] = useState(1);
    const [totalPlanningPages, selectedPlanningPage, planningPageArr] = usePlanningPage(cityData.cityId, selectedPageNumber);

    const [chartDataInfo] = useChartData(planningPage.planningActionId, cityData.cityId);
    const [chartDataOnScoreUpdateInfo] = useChartDataOnScoreUpdate(planningPage.planningActionId, cityData.cityId);

    const [averagesPerItemData] = useAveragesPerItem(cityData.cityId);
    const [averagesPerItemArr, setAveragesPerItemArr] = useState([]);

    const [newPageAdded, setNewPageAdded] = useState(false);

    const [deletePlanningActionInitiated, setDeletePlanningActionInitiated] = useState(false);
    const [pageDeleted, setPageDeleted] = useState(false);

    const [availableScores, setAvailableScores] = useState([]);

    const [currentActionPlanningData, setCurrentActionPlanningData] = useState({});

    const [scoreModified, setScoreModified] = useState(false);
    const [angleMouseOverValue, setAngleMouseOverValue] = useState(-1);

    const [resizeTriggered, setResizeTriggered] = useState(false);


    const fetchPlanningActions = async () => {
        setIsLoading(true);
        setLoadingMessage("Fetching planning actions...");
        setError("");

        try {
            //const token = "sometoken";
            await dispatch(planningActions.fetchPlanningActionsByCity(cityData.cityId));
            setIsLoading(prevState => !prevState);
            setLoadingMessage("");
        }
        catch (err) {
            setError(err.message);
            setIsLoading(false);
            setLoadingMessage("");
        };
    };

    const fetchChartData = async (planningActionId, cityId) => {
        setChartLoading(true);
        setLoadingMessage("Fetching chart data...");
        setError("");

        try {
            //const token = "sometoken";
            await dispatch(planningActions.fetchChartData(planningActionId, cityId));
            setChartLoading(false);
            setLoadingMessage("");
        }
        catch (err) {
            setError(err.message);
            setChartLoading(false);
            setLoadingMessage("");
        };
    };



    const fetchChartDataOnScoreItemUpdate = async (planningActionData, planningPageArr, cityId) => {
        setChartLoading(true);
        setLoadingMessage("Fetching chart data...");
        setError("");

        try {
            //const token = "sometoken";
            await dispatch(planningActions.fetchChartDataOnScoreItemUpdate(planningActionData, planningPageArr, cityId));
            setChartLoading(false);
            setLoadingMessage("");
        }
        catch (err) {
            setError(err.message);
            setChartLoading(false);
            setLoadingMessage("");
        };
    };


    const addPlanningAction = async (planningPageData) => {
        setIsLoading(true);
        setLoadingMessage("Adding planning action...");
        setError("");
        try {
            //const token = "sometoken";
            await dispatch(planningActions.addPlanningAction(planningPageData, cityData.cityId));
            setIsLoading(false);
            setLoadingMessage("");

            await fetchPlanningActions();
            toolbarItemClickHandler(totalPlanningPageCount + 1);
            //toolbarItemClickHandler(totalPlanningPageCount === 0 ? 1 : totalPlanningPageCount+1);
            //setTotalPlanningPageCount(-1);    
        }
        catch (err) {
            setError(err.message);
            setIsLoading(false);
            setLoadingMessage("");
        };
    };

    const updatePlanningActionHandler = async (planningActionData) => {
        //   debugger;
        setIsLoading(true);
        setLoadingMessage("Updating planning action...");
        setError("");
        try {
            //const token = "sometoken";
            await dispatch(planningActions.updatePlanningAction(planningActionData, cityData.cityId));
            setIsLoading(prevState => !prevState);
            setLoadingMessage("");
        }
        catch (err) {
            setError(err.message);
            setIsLoading(false);
            setLoadingMessage("");
        };
    };


    const deletePlanningActionHandler = async (planningActionData) => {
        setIsLoading(true);
        setLoadingMessage("Deleting planning action...");
        setError("");
        try {
            //const token = "sometoken";
            await dispatch(planningActions.deletePlanningAction(planningActionData, cityData.cityId));
            setIsLoading(prevState => !prevState);
            setLoadingMessage("");

            await fetchPlanningActions();


            toolbarItemClickHandler(selectedPageNumber !== 1 ? selectedPageNumber - 1 : (totalPlanningPageCount > 1 ? selectedPageNumber : 0))
            setDeletePlanningActionInitiated(true);
            //toolbarItemClickHandler(selectedPageNumber !== 1 ? selectedPageNumber - 1 : (totalPlanningPageCount > 1 ? selectedPageNumber : 0) )

            //toolbarItemClickHandler(selectedPageNumber !== 1 ? selectedPageNumber - 1 : totalPlanningPageCount-1)
            //setTotalPlanningPageCount(0);    
            //setTotalPlanningPageCount(selectedPageNumber !== 1 ? -1 : -1);    

            //toolbarItemClickHandler(selectedPageNumber !== 1 ? selectedPageNumber - 1 : totalPlanningPageCount - selectedPageNumber - 1 );
            //setTotalPlanningPageCount(totalPlanningPageCount-1);    


            //toolbarItemClickHandler(selectedPageNumber !== 1 ? selectedPageNumber - 1 : totalPlanningPageCount - selectedPageNumber - 1 );
            //setTotalPlanningPageCount(0);    

            //setSelectedPageNumber(selectedPageNumber !== 1 ? selectedPageNumber - 1 : totalPlanningPageCount - selectedPageNumber - 1);

            //prevPageNumber.current = selectedPageNumber - 1;
            //setTotalPlanningPageCount(totalPlanningPageCount-1);

            //setSelectedPageNumber(selectedPageNumber => selectedPageNumber !== 1 ? selectedPageNumber - 1 : totalPlanningPageCount - selectedPageNumber - 1);
            //prevPageNumber.current = 0;
            //setTotalPlanningPageCount(selectedPageNumber !== 1 ? -1 : 0);    
            //setTotalPlanningPageCount(0);
        }
        catch (err) {
            setError(err.message);
            setIsLoading(false);
            setLoadingMessage("");
        };
    };

    const toolbarItemClickHandler = (toolbarItemClickInfo) => {
        if ("NEW-PLANNING-ACTION" === toolbarItemClickInfo) {
            setNewPlanningActionInitiated(true);
        }
        else {
            //Should be some page number.
            setSelectedPageNumber(toolbarItemClickInfo);
            prevPageNumber.current = selectedPageNumber;
        }
    }

    const addNewPlanningActionHandler = (newPlanningAction) => {
        addPlanningAction(newPlanningAction);
        //setNewPlanningActionInitiated(false);

    }

    const cancelPlanningActionHandler = () => {
        setNewPlanningActionInitiated(false);
        setError("");
    }

    const scoreChangeHandler = (data) => {
        //console.log("scoreChangeHandler", data)
        const { actionId: planningActionId, actionName: name, actionDescription: description, scoreItems: items } = data;
        setCurrentActionPlanningData({
            planningActionId,
            name,
            description,
            items
        });

        setScoreModified(true);
    }

    const spiderChartData = prepareSpiderChartData(scoreItemsData, currentActionPlanningData, chartDataInfo.spiderChartData, chartDataOnScoreUpdateInfo.spiderChartData);
    const barChartData = prepareBarChartData(currentActionPlanningData.name, chartDataOnScoreUpdateInfo.barChartData);



    const finalResultClickHandler = () => {

        if (Object.keys(planningPage).length === 0) {
            return;
        }

        const finalSpiderChartData = prepareFinalSpiderChartData(scoreItemsData, chartDataInfo.spiderChartData);
        const [finalBarChartData, catAndSubCatHealthIndicatorInfo] = prepareFinalBarChartData(chartDataInfo.barChartData);
        const overallScoreData = chartDataInfo.overallScore;
        history.push({
            "pathname": `/urban-planning-portal/result`,
            "state": {
                "staticDataLoaded": props.staticDataLoaded,
                "scoreItems": scoreItemsData,
                "spiderChartData": finalSpiderChartData,
                "barChartData": finalBarChartData,
                "catAndSubCatHealthIndicatorInfo": catAndSubCatHealthIndicatorInfo,
                "overallScoreData": overallScoreData
            }
        });
    }

    const angleMouseOverHandler = (value) => {
        setAngleMouseOverValue(value);
    }

    const angleMouseOutHandler = (value) => {
        setAngleMouseOverValue(-1);
    }

    const axisLineMouseOverHandler = (value) => {
        setAngleMouseOverValue(value);
    }
    const axisLineMouseOutHandler = () => {
        setAngleMouseOverValue(-1);
    }



    const renderItems = () => {
        const itemsArr = undefined !== scoreItemsData
            && scoreItemsData.length > 0
            && scoreItemsData
                .filter((scoreItem, idxPos) => idxPos === angleMouseOverValue - 1)
                .map((scoreItem, idx) => {
                    const scoreNumberColor = ITEM_COLOR_MAPPING[(angleMouseOverValue - 1) % Object.keys(ITEM_COLOR_MAPPING).length].color;
                    return (
                        <div key={idx} className={styles.itemIdContainer}>
                            <div className={styles.itemId} style={{ backgroundColor: scoreNumberColor }}>{angleMouseOverValue}</div>
                            <div className={styles.itemTitle}>{scoreItem.title}</div>
                        </div>
                    )
                });
        return itemsArr;
    }

    useEffect(() => {
        if (undefined !== cityData && Object.keys(cityData).length > 0) {
            fetchPlanningActions();
        }
    }, [cityData])

    useEffect(() => {

        if (undefined !== selectedPlanningPage) {
            setPlanningPage(selectedPlanningPage);
            setTotalPlanningPageCount(totalPlanningPages);
            setCurrentActionPlanningData(selectedPlanningPage);

            if (true === newPlanningActionInitiated) {
                setNewPageAdded(true);
                setNewPlanningActionInitiated(false);
            }
            else if (true === deletePlanningActionInitiated) {
                setPageDeleted(true);
                setDeletePlanningActionInitiated(false);
            }
            if (undefined !== selectedPlanningPage.planningActionId) {
                fetchChartData(selectedPlanningPage.planningActionId, cityData.cityId);
            }

        }

    }, [totalPlanningPages, selectedPlanningPage])

    useEffect(() => {
        if (undefined !== averagesPerItemData && averagesPerItemData.length > 0) {
            setAveragesPerItemArr(averagesPerItemData)
        }
    }, [averagesPerItemData])



    useEffect(() => {
        if (selectedPageNumber !== prevPageNumber.current) {
            //setPlanningPage({});
        }
    }, [selectedPageNumber])



    useEffect(() => {
        if (false === newPlanningActionInitiated) {
            setNewPageAdded(false);
        }
    }, [newPlanningActionInitiated])

    useEffect(() => {
        if (false === deletePlanningActionInitiated) {
            setPageDeleted(false);
        }
    }, [deletePlanningActionInitiated])


    useEffect(() => {
        if (true === scoreModified) {
            //call the new api to get the updated charts.
            fetchChartDataOnScoreItemUpdate(currentActionPlanningData, planningPageArr, cityData.cityId);
            //ends here...
            setScoreModified(false);
        }
    }, [scoreModified])




    useEffect(() => {
        //console.log("staticDataLoaded")
        if (false === staticDataLoaded) {
            setError("App initialization is failed!")
        }
        else {
            setError("")
        }
    }, [props.staticDataLoaded]);



    useEffect(() => {
        if (undefined !== cityInfo) {
            setCityData(cityInfo);
        }

        if (undefined !== scores) {
            setScoresData(scores);
        }

        if (undefined !== scoreItems) {
            setScoreItemsData(scoreItems);
        }

    }, [cityInfo, scores, scoreItems])

    const [userManual, setUserManual] = useState(false)
    const manualHandler = () => {
        setUserManual(state => !state)
    }
    const closeHandler = () => {
        setUserManual(false)
    }
    return (
        <Container fluid>
            {
                undefined !== planningPage && true === props.staticDataLoaded
                    ?
                    <>
                        {/* for non-compatible devices */}
                        {userManual && <QuickScan onClose={closeHandler}

                        />}
                        <Row>
                            <Col>
                                <div className={styles.notCompatibleMessage}>
                                    Sorry! Mobile devices are not supported in the
                                    current version of Healthy Cities Generator.
                                    Please use Desktop/Laptop preferably using Google
                                    Chrome browser.
                                </div>
                            </Col>
                        </Row>

                        <Row className={styles.formNotCompatible} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Col md={6} lg={5}>
                                <Row>
                                    <Col >
                                        <Title level={2} style={{ float: 'left', paddingLeft: '36%' }} className={styles.heading}>Planning Actions {newPlanningActionInitiated ? "(New)" : ""}</Title>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col >
                                        <div className={styles.user_actions} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                                            <div className={styles.item} onClick={manualHandler}>Guidance</div>
                                            <div className={`${Object.keys(planningPage).length === 0 ? styles.disableLink : styles.item}`} onClick={finalResultClickHandler}>View Final Results</div>
                                        </div>
                                    </Col>
                                </Row>
                                {/* Pagination component */}
                                <Row>
                                    <Col>
                                        <Toolbar className={styles.toolbar} newPageInitiated={newPlanningActionInitiated} newPageAdded={newPageAdded} pageDeleted={pageDeleted} prevPageNumber={prevPageNumber.current} currentPage={selectedPageNumber} pageSize={totalPlanningPageCount} onClick={toolbarItemClickHandler} />
                                    </Col>
                                </Row>
                                {/* These are the questions to be filled*/}
                                <Row>
                                    <Col>
                                        {
                                            true === newPlanningActionInitiated // check if plan exists
                                                ?

                                                <CreatePlanningActionComp onCreate={addNewPlanningActionHandler} onCancel={cancelPlanningActionHandler} scores={scoresData} items={scoreItemsData} />
                                                :
                                                Object.keys(planningPage).length > 0
                                                    ?

                                                    <PlanningActionComp onUpdate={updatePlanningActionHandler} onDelete={deletePlanningActionHandler} onScoreChange={scoreChangeHandler} planningPage={planningPage} scores={scoresData} />

                                                    :
                                                    <div className={styles.emptyPlanningAction}>
                                                        <p>Please create a new Action Planning Page by clicking the <PlusOutlined className={`${styles.plusButtonIcon}`} /> icon on the Toolbar.</p>
                                                    </div>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            {/* show this when chart renders - same level with title */}
                            <Col md={6} lg={5}>
                                <Row>

                                    <Col md={12}>
                                        {
                                            Object.keys(planningPage).length > 0
                                                ?
                                                undefined !== spiderChartData && spiderChartData.length > 0
                                                    ?
                                                    <>
                                                        <div className={`${styles.statusMsg} ${true === chartLoading && styles.fadeInMsg}`}>
                                                            Updating the chart...Please wait!!!
                                                        </div>
                                                    </>
                                                    :
                                                    <ShowSpinnerIfBusy loading={true} loadingMessage={"Preparing Chart..."} className={styles.loading} />
                                                :
                                                <div className={styles.chatsUnavailable}>No Action Planning page is configured to visualize the data.</div>
                                        }
                                    </Col>
                                </Row>
                                {/*  Chart component - spider */}
                                <Row>
                                    <Col md={12} lg={12} >
                                        <div className={styles.spiderChartContainer}>
                                            {
                                                undefined !== spiderChartData && spiderChartData.length > 0
                                                    ?
                                                    <>
                                                        <div style={{ marginTop: 31}}>
                                                            <Link className={styles.item} to="/pdfs/Urban_Determinants_of_Health.pdf.pdf" target="_blank" download>Urban determinants: find out more</Link>
                                                        </div>
                                                        <SpiderChartComp
                                                            currentActionPlanningName={currentActionPlanningData.name}
                                                            data={spiderChartData}
                                                            innerRadius={"75%"}
                                                            outerRadius={"80%"}
                                                            onAngleMouseOver={angleMouseOverHandler}
                                                            onAngleMouseOut={angleMouseOutHandler}
                                                            onAxisLineMouseOver={axisLineMouseOverHandler}
                                                            onAxisLineMouseOut={axisLineMouseOutHandler}
                                                        />

                                                    </>

                                                    :
                                                    ""
                                            }

                                        </div>
                                    </Col>
                                    {/* show this when hovering with mouse */}
                                    {
                                        <Col md={12} lg={12} >
                                            {
                                                undefined !== spiderChartData && spiderChartData.length > 0
                                                    ?
                                                    <div className={`${styles.itemsContainer} ${-1 === angleMouseOverValue ? styles.fadeOut : styles.fadeIn}`}>
                                                        {
                                                            -1 === angleMouseOverValue
                                                                ?
                                                                <div className={styles.mouseOverMsg}>Please mouse over on the chart to see the description.</div>
                                                                :
                                                                renderItems()
                                                        }
                                                    </div>
                                                    :
                                                    ""
                                            }
                                        </Col>
                                    }
                                </Row>
                                {/* bar chart */}
                                <Row>
                                    <Col md={12}>
                                        <div className={styles.barChartContainer}>
                                            {
                                                undefined !== barChartData && barChartData.length > 0
                                                    ?
                                                    <BarChartComp
                                                        currentActionPlanningName={currentActionPlanningData.name}
                                                        data={barChartData}
                                                        applyCategoryColor={true}
                                                    />
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                    :
                    ""
            }

        </Container>
    );
}
export default PlanningComp;