import React from 'react'
import { Button } from 'antd';
import styles from './UserManual.module.css'
import urban_logo from '../../../../../assets/images/health-input-logo.png';

export default function UserManual({ onClose }) {
    return (
        <div className={styles.modal} >
            <div className={styles.modal_body}>
                <div className={styles.userManual_head}>
                    <img alt='health-logo' height='100' className={styles.userManual_head_img} src={urban_logo} />
                    <div className={styles.userManual_head_text}  >
                        <h1>Health</h1>
                        <div>
                            This approach will help you align your <span style={{ fontWeight: 700 }}>health priorities</span> with urban strategies.
                            The tool will guide you in defining the urban actions needed to improve
                            specific health outcomes.
                        </div>
                    </div>
                </div>
                <ul className={styles.userManual_body}>
                    <li>
                        <span>{`Select the different health indicators you want to improve.`}</span>
                    </li>
                    <li>
                        <span >
                            {`The Urban Determinants of Health that are relevant to your selection will be
                              displayed on the right. By clicking on the information icon you can find out more about each one and discover 
                              concrete actions that will help you meet your health goals.`}
                        </span>
                    </li>
                </ul>
                <div className={styles.modal_submit_wrapper}>
                    <Button type="secondary" className={styles.closeButton} onClick={onClose}>Close</Button>
                </div>
            </div>
        </div >
    )
}

