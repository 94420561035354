import {
	CITY_INFO_FETCHED, SCORES_FETCHED, SCORE_ITEMS_FETCHED
} from "../actions/types";

const initialState = {
	cityInfo: {},
	scores: [],
    scoreItems: []
}

export default (state = initialState, action) => {
	switch(action.type) {
		case CITY_INFO_FETCHED:
			return {
				...state,
				cityInfo: action.data
			}

		case SCORES_FETCHED:
			return {
				...state,
				scores: action.data
			}

		case SCORE_ITEMS_FETCHED:
			return {
				...state,
				scoreItems: action.data
			}

		default:
			return state;
	}
}

